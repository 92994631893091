import EditIcon from '@material-ui/icons/Edit';
import React, {Component} from "react";
import {Redirect} from "react-router";
import {APP_URLS} from "../../appUrl";

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectURL: null
		};
	}

	render() {
		let {redirectURL} = this.state;
		return (
			<div>
				{redirectURL ? (
					<Redirect to={redirectURL}/>
				) : null}
				<EditIcon onClick={() => {
					this.setState({
						redirectURL: APP_URLS.EDIT_JOB_POST.url + "/" + this.props.rowData._id
					});
				}}/>
			</div>
		);
	}
}

export default Edit;