import {withStyles} from "@material-ui/core/styles/index";
import React, {Component} from "react";
import {getCandidateSkillText} from "../../non-auth-pages/job-view-public/resumeUploadOptions";
import Resume from "./resumeViewDialog";
import Feedback from "./feedbackDialog";
import ChangeStatus from "./changeStatus";

const styles = theme => ({
	rowContainer: {
		width: '100%',
		display: 'block', // Fix IE 11 issue.
		flexGrow: 1
	},
	dataRow: {
		margin: "10px 0 10px 0"
	},
	fieldTitle: {
		fontWeight: 700,
		margin: "0 0 5px"
	},
	actionContainer: {
		display: "inline",
		width: "50%",
		float: "left",
		padding: "10px 0 0"
	}
});

class MobileView extends Component {
	getClientName = () => {
		let {client, rowData} = this.props,
			clientName = "-";
		for (let i in client) {
			if (client.hasOwnProperty(i) && rowData.clientName === client[i]._id) {
				clientName = client[i].clientName;
				break;
			}
		}
		return clientName;
	};
	getEndClientName = () => {
		let {endClient, rowData} = this.props,
			endClientName = "-";
		for (let i in endClient) {
			if (endClient.hasOwnProperty(i) && rowData.endClientName === endClient[i]._id) {
				endClientName = endClient[i].endClientName;
				break;
			}
		}
		return endClientName;
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let {classes, status, changeResumeStatus} = this.props;
		let {
			firstName,
			lastName,
			emailId,
			phone,
			yearsOfExperience,
			currentLocation,
			expectedSalary,
			skills
		} = this.props.rowData;
		const skillList = skills ? skills.split(",") : [];
		return (
			<div className={classes.rowContainer}>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Name:</div>
					<div>{firstName + " " + lastName}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Email Id:</div>
					<div>{emailId}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Phone:</div>
					<div>{phone}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Experience (in years):</div>
					<div>{yearsOfExperience}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Location:</div>
					<div>{currentLocation}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Expected Salary:</div>
					<div>{expectedSalary}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Skills:</div>
					<div>
						{skillList.map((skill, index) => {
							return (
								<span key={index} style={{margin: "0 10px 0 0"}}>
									{getCandidateSkillText(skill) + ((index + 1) === skills.length ? "" : ",")}
								</span>
							);
						})}
					</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Change Status:</div>
					<div>
						<ChangeStatus {...this.props} changeResumeStatus={changeResumeStatus} status={status}/>
					</div>
				</div>
				<div className={classes.dataRow}>
					<Feedback rowData={this.props.rowData}/>
				</div>
				<div className={classes.dataRow}>
					<Resume rowData={this.props.rowData}/>
				</div>
			</div>
		);
	}
}

export default (withStyles(styles)(MobileView));