import React from "react";
import {isMobile} from "../../../utils/detectBrowser";
import ChangeStatus from "./changeStatus";
import Edit from "./editJob";
import MobileView from "./mobileView";

export const config = (client, endClient, status, onJobStatusChange) => {
	if (isMobile.any()) {
		return [{
			flexGrow: 1.0,
			width: 500,
			headerStyle: {
				height: 0
			},
			cellContentRenderer: (props) => {
				return (
					<MobileView {...props} client={client} endClient={endClient} status={status}
					            onJobStatusChange={onJobStatusChange}/>
				);
			}
		}];
	}
	else {
		return [{
			width: 200,
			label: 'Submission Close Date',
			dataKey: 'submissionCloseDate'
		}, {
			width: 120,
			flexGrow: 1.0,
			label: 'Client',
			cellContentRenderer: (props) => {
				let clientName = "-";
				for (let i in client) {
					if (client.hasOwnProperty(i) && props.rowData.clientName === client[i]._id) {
						clientName = client[i].clientName;
						break;
					}
				}
				return clientName;
			}
		}, {
			width: 120,
			flexGrow: 1.0,
			label: 'End Client Name',
			cellContentRenderer: (props) => {
				let endClientName = "-";
				for (let i in endClient) {
					if (endClient.hasOwnProperty(i) && props.rowData.endClientName === endClient[i]._id) {
						endClientName = endClient[i].endClientName;
						break;
					}
				}
				return endClientName;
			}
		}, {
			width: 150,
			label: 'Job Position',
			dataKey: 'jobPosition'
		}, {
			width: 120,
			flexGrow: 1.0,
			label: 'Location',
			dataKey: 'jobLocation'
		}, {
			label: 'Change Status',
			width: 150,
			cellContentRenderer: (props) => {
				return (
					<ChangeStatus {...props} onJobStatusChange={onJobStatusChange} status={status}/>
				);
			}
		}, {
			label: '',
			width: 60,
			cellContentRenderer: (props) => {
				return (
					<Edit {...props}/>
				);
			}
		}];
	}
};