import DeleteIcon from '@material-ui/icons/Delete';
import React, {Component} from "react";
import AlertDialog from "./confirmRemoveEmailDialog";

class RemoveEmail extends Component {
	openCloseConfirmDialog = () => {
		this.setState({
			displayConfirmDialog: !this.state.displayConfirmDialog
		});
	};
	removeMailFromMailingList = () => {
		this.props.removeFromMailingList(this.props.rowData._id, this.props.rowData.email);
		this.openCloseConfirmDialog();
	};

	constructor(props) {
		super(props);
		this.state = {
			displayConfirmDialog: false
		};
	}

	render() {
		let {displayConfirmDialog} = this.state;
		return (
			<div>
				{displayConfirmDialog ? (
					<AlertDialog closeDialog={this.openCloseConfirmDialog}
					             email={this.props.rowData.email}
					             removeMailFromMailingList={this.removeMailFromMailingList}/>
				) : null}
				<DeleteIcon onClick={this.openCloseConfirmDialog}/>
			</div>
		);
	}
}

export default RemoveEmail