import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from "@material-ui/core/styles/index";
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import Work from '@material-ui/icons/Work';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {APP_URLS} from "../appUrl";

const styles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing.unit * 4,
	},
});

class MenuItems extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {classes} = this.props;
		return (
			<List>
				<div className={"menuOptions"}>
					<ListItem button>
						<Link to={APP_URLS.DASHBOARD_HOME.url}>
							<ListItemIcon>
								<DashboardIcon/>
							</ListItemIcon>
							<ListItemText primary="Dashboard"/>
						</Link>
					</ListItem>
					<ListItem button>
						<Link to={APP_URLS.NEW_JOB_POST.url}>
							<ListItemIcon>
								<Work/>
							</ListItemIcon>
							<ListItemText primary="Post New Job"/>
						</Link>
					</ListItem>
					<ListItem button>
						<Link onClick={this.handleClick} to={APP_URLS.MAILING_LIST.url}>
							<ListItemIcon>
								<PeopleIcon/>
							</ListItemIcon>
							<ListItemText primary="Mailing List"/>
						</Link>
					</ListItem>
				</div>
			</List>
		);
	}
}

MenuItems.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(MenuItems));