import BackIcon from '@material-ui/icons/ArrowBack';
import React, {Component} from "react";
import * as JobMenu from "../job-post/jobMenuOptions";

class JobDetails extends Component {
	render() {
		let {data, onBack} = this.props;
		let s0 = {padding: "40px"},
			s1 = {fontWeight: 700, margin: "0 20px 0 0", padding: "10px 0"},
			s2 = {fontWeight: 700},
			s3 = {border: "solid 1px #000", padding: "10px"},
			s4 = {cursor: "pointer"};
		return (
			<div style={s0}>
				<div style={s4} onClick={onBack}>
					<BackIcon/>
				</div>
				<table>
					<tr>
						<td style={s1}>Title:</td>
						<td>{`${data.jobPosition}`}</td>
					</tr>
					<tr>
						<td style={s1}>Submission Close Date:</td>
						<td>{`${data.submissionCloseDate}`}</td>
					</tr>
					<tr>
						<td style={s1}>C2C Rate:</td>
						<td>{`${data.cTwoCRate}  per hour`}</td>
					</tr>
					<tr>
						<td style={s1}>W2 Rate:</td>
						<td>{`${data.wTwoRate}`}</td>
					</tr>
					<tr>
						<td style={s1}>Interview Type:</td>
						<td>{`${JobMenu.getInterviewName(data.interviewType)}`}</td>
					</tr>
					<tr>
						<td style={s1}>Position Type:</td>
						<td>{`${JobMenu.getJobType(data.jobType)}`}</td>
					</tr>
					<tr>
						<td style={s1}>Location:</td>
						<td>{`${data.jobLocation}`}</td>
					</tr>
					<tr>
						<td style={s1}>Job Duration:</td>
						<td>{`${data.jobDuration} + months`}</td>
					</tr>
				</table>
				<br/>
				<div>Detail :</div>
				<div style={s3}>
					<div>{`${data.jobPosition}`}</div>
					<br/>
					<div style={s2}>Required Skills:</div>
					<div>{`${data.requiredSkills}`}</div>
					<br/>
					<div style={s2}>Preferred Skills:</div>
					<div>{`${data.preferredSkills}`}</div>
					<br/>
					<div style={s2}>Role description:</div>
					<div>{`${data.responsibility}`}</div>
				</div>
			</div>
		);
	}
}

export default JobDetails;