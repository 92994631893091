import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from "react-redux";
import {toast} from 'react-toastify';
import {
	GetClientList,
	GetEndClientList,
	GetJobDetails,
	PostNewJob,
	SaveNewClientName,
	SaveNewEndClientName
} from "../../../redux/commonStoreAndActions/job-actions";
import * as MenuOptions from "./jobMenuOptions";
import NewClientDialog from "./newClientDialog";
import NewEndClientDialog from "./newEndClientDialog";

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: "100%",
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	divider: {
		marginTop: 40
	},
	formControl: {
		margin: "16px 8px 0",
		minWidth: "100%"
	},
	appBarSpacer: theme.mixins.toolbar,
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
	button: {
		margin: 40
	},
	fab: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	checkbox: {
		margin: "0 0 0 5px"
	}
});

class JobPost extends Component {
	componentDidMount = () => {
		let {clientList, endClientList} = this.props.job,
			{jobId} = this.props;
		this.props.GetClientList(this.onClientDataFetched);
		this.props.GetEndClientList(this.onEndClientDataFetched);
		if (jobId) {
			this.props.GetJobDetails(jobId, this.onJobDetailsFetched);
		}
	};
	onJobDetailsFetched = (data) => {
		this.setState({
			jobPosition: data.jobPosition || "",
			jobCode: data.jobCode || "",
			jobType: data.jobType || "w2_c2c",
			jobLocation: data.jobLocation || "",
			jobDuration: data.jobDuration || "",
			interviewType: data.interviewType || "video_call",
			minExperienceRequired: data.minExperienceRequired || 5,
			workAuthorization: data.workAuthorization || "any_permit",
			wTwoRate: data.wTwoRate || "",
			cTwoCRate: data.cTwoCRate || "",
			isRateNegotiable: data.isRateNegotiable || false,
			submissionCloseDate: data.submissionCloseDate || "",
			responsibility: data.responsibility || "",
			requiredSkills: data.requiredSkills || "",
			preferredSkills: data.preferredSkills || "",
			clientName: data.clientName || "",
			endClientName: data.endClientName || "",
			projectName: data.projectName || "",
			isRemoteJob: data.isRemoteJob || "no",
			isSponsorshipAvailable: data.isSponsorshipAvailable || "no",
			jobRate: data.jobRate || "",
			jobStatus: data.jobStatus || "open",
			mailingListCategory: data.mailingListCategory || [],
			jobNotes: data.jobNotes || "",
			jobPostDate: data.jobPostDate || ""
		});
	};
	onClientDataFetched = (data) => {
		MenuOptions.setClientName(data);
		this.setState({});
	};
	onEndClientDataFetched = (data) => {
		MenuOptions.setEndClientName(data);
		this.setState({});
	};
	handleChange = (e, stateName, directValue) => {
		let update = {};
		update[stateName] = directValue ? directValue.value : e.target.value;
		this.setState(update);
	};
	onFormSubmit = (e) => {
		e.preventDefault();
		if (this.validate()) {
			let {jobId} = this.props,
				data = this.state;
			if (jobId) {
				data._id = jobId;
			}
			this.props.PostNewJob(data);
		}
	};
	validate = () => {
		let {
			jobPosition,
			jobType,
			jobLocation,
			jobDuration,
			interviewType,
			requiredSkills,
			mailingListCategory

		} = this.state;

		return (
			jobPosition && jobPosition.length > 3 &&
			jobType && jobType.length > 0 && this.checkValue("jobType", jobType) &&
			interviewType && interviewType.length > 0 && this.checkValue("interviewType", interviewType) &&
			requiredSkills && requiredSkills.length > 0 &&
			mailingListCategory && mailingListCategory.length > 0 && this.checkValueAllValues("categories", mailingListCategory)
		);
	};
	checkValueAllValues = (field, value) => {
		let isAllAvailable = true,
			options = MenuOptions[field];
		for (let i in value) {
			if (value.hasOwnProperty(i)) {
				let flag = false;
				for (let j in options) {
					if (options.hasOwnProperty(j)) {
						if (options[j].value === value[i]) {
							flag = true;
							break;
						}
					}
				}
				if (!flag) {
					isAllAvailable = false;
					break;
				}
			}
		}
		return isAllAvailable;
	};
	checkValue = (field, value) => {
		let isAvailable = false,
			options = MenuOptions[field];
		for (let i in options) {
			if (options.hasOwnProperty(i)) {
				let item = options[i];
				if (item.value === value) {
					isAvailable = true;
					break;
				}
			}
		}
		return isAvailable;
	};
	getMenu = (field) => {
		let menus = [];
		menus.push(<MenuItem value=""><em>None</em></MenuItem>);
		MenuOptions[field].map((item, index) => {
			menus.push(<MenuItem value={item.value} key={index}>{item.text}</MenuItem>);
		});
		return menus;
	};
	openCloseNewClientDialog = () => {
		this.setState({
			openNewClientDialog: !this.state.openNewClientDialog
		});
	};
	openCloseNewEndClientDialog = () => {
		this.setState({
			openNewEndClientDialog: !this.state.openNewEndClientDialog
		});
	};

	createNewClient = (clientName) => {
		let {SaveNewClientName, GetClientList} = this.props;
		if (SaveNewClientName) {
			SaveNewClientName({clientName: clientName}, () => {
				toast.info(<div>{"Successfully saved new client:  " + clientName}</div>);
				GetClientList(this.onClientDataFetched);
			});
		}
		this.openCloseNewClientDialog();
	};
	createNewEndClient = (endClientName, endClientState) => {
		let {SaveNewEndClientName, GetEndClientList} = this.props;
		if (SaveNewEndClientName) {
			SaveNewEndClientName({
				endClientName: endClientName,
				endClientState: endClientState
			}, () => {
				toast.info(<div>{"Successfully saved new end client:  " + endClientName}</div>);
				GetEndClientList(this.onEndClientDataFetched);
			});
		}
		this.openCloseNewEndClientDialog();
	};

	constructor(props) {
		super(props);
		this.state = {
			openNewClientDialog: false,
			openNewEndClientDialog: false,

			jobPosition: "",
			jobCode: "",
			jobType: "w2_c2c",
			jobLocation: "",
			jobDuration: 12,
			interviewType: "video_call",
			minExperienceRequired: 5,
			workAuthorization: "any_permit",
			wTwoRate: "",
			cTwoCRate: "",
			isRateNegotiable: false,
			submissionCloseDate: "",
			responsibility: "",
			requiredSkills: "",
			preferredSkills: "",
			clientName: "",
			endClientName: "",
			projectName: "",
			isRemoteJob: "no",
			isSponsorshipAvailable: "no",
			jobRate: "",
			jobStatus: "open",
			mailingListCategory: [],
			jobNotes: "",
			jobPostDate: ""
		};
	}

	render() {
		const {classes, jobId} = this.props;
		let {
			jobPosition,
			jobCode,
			jobType,
			jobLocation,
			jobDuration,
			interviewType,
			minExperienceRequired,
			workAuthorization,
			wTwoRate,
			cTwoCRate,
			isRateNegotiable,
			submissionCloseDate,
			responsibility,
			requiredSkills,
			preferredSkills,
			clientName,
			endClientName,
			projectName,
			isRemoteJob,
			isSponsorshipAvailable,
			jobRate,
			jobStatus,
			mailingListCategory,
			jobNotes,
			jobPostDate,

			openNewClientDialog,
			openNewEndClientDialog
		} = this.state;
		return (
			<div>
				<Typography variant="h4" gutterBottom component="h4">
					{jobId ? "Edit - " + jobPosition + " (" + jobCode + ")" : "Post New Job"}
				</Typography>
				{openNewClientDialog ? (
					<NewClientDialog closeDialog={this.openCloseNewClientDialog} onSubmit={this.createNewClient}/>
				) : null}
				{openNewEndClientDialog ? (
					<NewEndClientDialog closeDialog={this.openCloseNewEndClientDialog}
					                    onSubmit={this.createNewEndClient}/>
				) : null}
				<form onSubmit={this.onFormSubmit} className={classes.form}>
					<Grid container className={classes.root} xs={12} sm={12} justify="center" spacing={32}>
						<Grid item xs={12} sm={10}>
							<TextField
								id="job-position"
								label="Job Position"
								required
								className={classes.textField}
								value={jobPosition}
								onChange={(e) => {
									this.handleChange(e, "jobPosition")
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="job-code"
								label="Job Code"
								className={classes.textField}
								value={jobCode}
								onChange={(e) => {
									this.handleChange(e, "jobCode")
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="job-type">Job Type *</InputLabel>
								<Select
									value={jobType}
									required
									onChange={(e) => {
										this.handleChange(e, "jobType");
									}}
									inputProps={{
										name: 'jobType',
										id: 'job-type',
									}}>
									{this.getMenu("jobType")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="job-location"
								label="Job Location"
								className={classes.textField}
								value={jobLocation}
								onChange={(e) => {
									this.handleChange(e, "jobLocation");
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="job-duration">Job Duration (in Months)</InputLabel>
								<Select
									value={jobDuration}
									onChange={(e) => {
										this.handleChange(e, "jobDuration");
									}}
									inputProps={{
										name: 'jobDuration',
										id: 'job-duration',
									}}>
									{this.getMenu("jobDuration")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="interview-type">Interview Type *</InputLabel>
								<Select
									value={interviewType}
									onChange={(e) => {
										this.handleChange(e, "interviewType")
									}}
									inputProps={{
										name: 'interviewType',
										id: 'interview-type',
									}}>
									{this.getMenu("interviewType")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="min-experience-required">
									Minimum Experience Required (in Years)
								</InputLabel>
								<Select
									value={minExperienceRequired}
									onChange={(e) => {
										this.handleChange(e, "minExperienceRequired")
									}}
									inputProps={{
										name: 'minExperienceRequired',
										id: 'min-experience-required',
									}}>
									{this.getMenu("minExperienceRequired")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={10} spacing={164}>
							<Grid item xs={12} sm={6}>
								<FormControl className={classes.formControl}>
									<InputLabel htmlFor="work-authorization">Work Authorization</InputLabel>
									<Select
										value={workAuthorization}
										onChange={(e) => {
											this.handleChange(e, "workAuthorization")
										}}
										inputProps={{
											name: 'workAuthorization',
											id: 'work-authorization',
										}}>
										{this.getMenu("workAuthorization")}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>{" "}</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Divider className={classes.divider}/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="min-experience-required"
								label="W2 Rate"
								className={classes.textField}
								value={wTwoRate}
								type={"text"}
								onChange={(e) => {
									this.handleChange(e, "wTwoRate");
								}}
								margin="normal"/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="c-two-c-rate"
								label="C2C Rate"
								type={"text"}
								className={classes.textField}
								value={cTwoCRate}
								onChange={(e) => {
									this.handleChange(e, "cTwoCRate");
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5} alignItems="center">
							<FormControlLabel
								control={
									<Checkbox
										className={classes.checkbox}
										id="is-rate-negotiable"
										checked={isRateNegotiable}
										onChange={(e, isChecked) => {
											this.handleChange(e, "isRateNegotiable", {value: isChecked});
										}}
										value="isRateNegotiable"
										color="primary"
									/>
								}
								label="Rate Negotiable"
							/>
						</Grid>
						<Grid item xs={12} sm={5} spacing={32}/>
						<Grid item xs={12} sm={5} spacing={32}>
							<Grid item xs={12}>
								<TextField
									id="submission-close-date"
									type="date"
									label="Submission Close Date"
									className={classes.textField}
									value={submissionCloseDate}
									onChange={(e) => {
										this.handleChange(e, "submissionCloseDate");
									}}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"/>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={5} spacing={32}>
							<Grid item xs={12}>
								<TextField
									id="job-post-date"
									type="date"
									label="Job Post Date"
									className={classes.textField}
									value={jobPostDate}
									onChange={(e) => {
										this.handleChange(e, "jobPostDate");
									}}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"/>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Divider className={classes.divider}/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="required-skills"
								label={"Required Skills" + (requiredSkills && requiredSkills.length ? " (" + requiredSkills.length + " / 2000)" : "")}
								multiline
								required
								rowsMax="16"
								className={classes.textField}
								value={requiredSkills}
								onChange={(e) => {
									let value = e.target.value;
									if (value.length > 2000) {
										return;
									}
									this.handleChange(e, "requiredSkills");
								}}
								margin="normal"/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="responsibility-required"
								label={"Role description" + (responsibility && responsibility.length ? " (" + responsibility.length + " / 5000)" : "")}
								multiline
								rowsMax="16"
								className={classes.textField}
								value={responsibility}
								onChange={(e) => {
									let value = e.target.value;
									if (value.length > 5000) {
										return;
									}
									this.handleChange(e, "responsibility");
								}}
								margin="normal"/>
						</Grid>
						<Grid item xs={12} sm={10}>
							<TextField
								id="preferred-skills"
								label={"Preferred Skills" + (preferredSkills && preferredSkills.length ? " (" + preferredSkills.length + " / 2000)" : "")}
								multiline
								rowsMax="16"
								className={classes.textField}
								value={preferredSkills}
								onChange={(e) => {
									let value = e.target.value;
									if (value.length > 2000) {
										return;
									}
									this.handleChange(e, "preferredSkills");
								}}
								margin="normal"/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Divider className={classes.divider}/>
						</Grid>
						<Grid item xs={8} sm={4}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="client-name">
									Client
								</InputLabel>
								<Select
									value={clientName}
									onChange={(e) => {
										this.handleChange(e, "clientName")
									}}
									inputProps={{
										name: 'clientName',
										id: 'client-name',
									}}>
									{this.getMenu("clientName")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4} sm={1}>
							<Fab color="primary" aria-label="Add" className={classes.fab}>
								<AddIcon onClick={this.openCloseNewClientDialog}/>
							</Fab>
						</Grid>
						<Grid item xs={8} sm={4}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="end-client-name">End Client</InputLabel>
								<Select
									value={endClientName}
									onChange={(e) => {
										this.handleChange(e, "endClientName")
									}}
									inputProps={{
										name: 'endClientName',
										id: 'end-client-name',
									}}>
									{this.getMenu("endClientName")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4} sm={1}>
							<Fab color="primary" aria-label="Add" className={classes.fab}>
								<AddIcon onClick={this.openCloseNewEndClientDialog}/>
							</Fab>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="project-name"
								label="Project Name"
								className={classes.textField}
								value={projectName}
								onChange={(e) => {
									this.handleChange(e, "projectName");
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend">Remote Job</FormLabel>
								<RadioGroup
									aria-label="Is Remote Job"
									name="isRemoteJob"
									className={classes.group}
									value={isRemoteJob}
									onChange={(e) => {
										this.handleChange(e, "isRemoteJob");
									}}>
									<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes"/>
									<FormControlLabel value="no" control={<Radio color="primary"/>} label="No"/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="job-rates"
								label="Job Rates"
								type={"number"}
								className={classes.textField}
								value={jobRate}
								onChange={(e) => {
									this.handleChange(e, "jobRate");
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend">Sponsorship Available</FormLabel>
								<RadioGroup
									aria-label="Is Sponsorship Available"
									name="isSponsorshipAvailable"
									className={classes.group}
									value={isSponsorshipAvailable}
									onChange={(e) => {
										this.handleChange(e, "isSponsorshipAvailable");
									}}>
									<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes"/>
									<FormControlLabel value="no" control={<Radio color="primary"/>} label="No"/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="job-status">Job Status</InputLabel>
								<Select
									value={jobStatus}
									onChange={(e) => {
										this.handleChange(e, "jobStatus")
									}}
									inputProps={{
										name: 'jobStatus',
										id: 'job-status',
									}}>
									{this.getMenu("jobStatus")}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={5}>
							<FormControl className={classes.formControl} required>
								<InputLabel htmlFor="mailing-list-category">Mailing List</InputLabel>
								<Select
									multiple
									value={mailingListCategory}
									onChange={(e) => {
										this.handleChange(e, "mailingListCategory")
									}}
									input={<Input id="mailing-list-category"/>}
									renderValue={selected => {
										let text = "";
										for (let i in selected) {
											if (selected.hasOwnProperty(i)) {
												text += MenuOptions.getCategoryName(selected[i]) + ", "
											}
										}
										text = text.substring(0, text.length - 2);
										return text;
									}}
									MenuProps={MenuProps}>
									{MenuOptions.categories.map((item, index) => (
										<MenuItem key={index} value={item.value}>
											<Checkbox color={"primary"}
											          checked={mailingListCategory.indexOf(item.value) > -1}/>
											<ListItemText primary={item.text}/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={10}>
							<TextField
								id="job-notes"
								label={"Notes"+ (jobNotes && jobNotes.length ? " (" + jobNotes.length + " / 5000)" : "")}
								type={"text"}
								className={classes.textField}
								value={jobNotes}
								onChange={(e) => {
									let value = e.target.value;
									if (value.length > 5000) {
										return;
									}
									this.handleChange(e, "jobNotes");
								}}
								margin="normal"
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Divider className={classes.divider}/>
						</Grid>
						<Grid container className={classes.root} xs={12} justify="center">
							<Button type={"submit"} disabled={!this.validate()} variant="contained" color="primary"
							        className={classes.button}>
								{jobId ? "Save" : "Submit"}
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		);
	}
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

JobPost.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	signInUser: state.signInUser,
	job: state.job
});

export default connect(mapStateToProps, {
	PostNewJob,
	GetClientList,
	GetJobDetails,
	GetEndClientList,
	SaveNewClientName,
	SaveNewEndClientName
})(withStyles(styles)(JobPost));