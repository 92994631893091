import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import App from './App';

import './index.css';
import configureStore from './redux/configureStore';
import * as serviceWorker from './serviceWorker';

window.collabriumSystems = {};
window.collabriumSystems.isUserAuthenticated = document.getElementById("isA").value;
ReactDOM.render((
	<Provider store={configureStore()}>
		<App/>
	</Provider>
), document.getElementById('root'));

serviceWorker.unregister();
