export const getCandidateAvailability = (value) => {
	let returnValue = "-";
	for (let i in candidateAvailability) {
		if (candidateAvailability.hasOwnProperty(i) && candidateAvailability[i].value === value) {
			returnValue = candidateAvailability[i].text;
			break;
		}
	}
	return returnValue;
};

export const candidateAvailability = [{
	text: "In 1 week", value: "1-week"
}, {
	text: "In 2 week", value: "2-week"
}, {
	text: "More than a month", value: "1-month-plus"
}];

export const getWorkAuthorization = (value) => {
	let returnValue = "-";
	for (let i in workAuthorization) {
		if (workAuthorization.hasOwnProperty(i) && workAuthorization[i].value === value) {
			returnValue = workAuthorization[i].text;
			break;
		}
	}
	return returnValue;
};

export const workAuthorization = [{
	text: "Citizen", value: "citizen"
}, {
	text: "Green Card", value: "green-card"
}, {
	text: "H1 B", value: "h1-b"
}, {
	text: "H4 EAD", value: "h4-ead"
}, {
	text: "OPT", value: "opt"
}, {
	text: "Other", value: "other"
}];

export const getCandidateSkillText = (value) => {
	let returnValue = "-";
	for (let i in candidateSkillTypes) {
		if (candidateSkillTypes.hasOwnProperty(i) && candidateSkillTypes[i].value === value) {
			returnValue = candidateSkillTypes[i].text;
			break;
		}
	}
	return returnValue;
};

export const candidateSkillTypes = [{
	text: "Java", value: "java"
}, {
	text: ".Net", value: ".net"
}, {
	text: "QA", value: "qa"
}, {
	text: "DBA", value: "dba"
}, {
	text: "Server Admin", value: "server_admin"
}, {
	text: "Business Analyst", value: "business_analyst"
}, {
	text: "Project Management", value: "project_management"
}, {
	text: "ETL", value: "etl"
}, {
	text: "Other", value: "other"
}];