import axios from "axios";
import {APIs} from "../../api/index";

export const TYPES = {
	GET_SERVICE: "GET_SERVICE_18"
};

export const GetServiceInfo = (serviceName) => {
	const request = axios.get(APIs.GET_SERVICE_PUBLIC + "/" + serviceName);
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.GET_SERVICE,
				payload: data
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};