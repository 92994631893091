import validator from "validator";
import * as MenuOptions from "./resumeUploadOptions";

const checkValue = (field, value) => {
	let isAvailable = false,
		options = MenuOptions[field];
	for (let i in options) {
		if (options.hasOwnProperty(i)) {
			let item = options[i];
			if (item.value === value) {
				isAvailable = true;
				break;
			}
		}
	}
	return isAvailable;
};

export const validData = (data) => {
	let {
		firstName,
		lastName,
		emailId,
		phone,
		yearsOfExperience,
		workAuthorization,
		availability,
		resume = null,
	} = data;

	return firstName && firstName.length &&
		lastName && lastName.length &&
		phone && phone.length &&
		yearsOfExperience && yearsOfExperience.length && !isNaN(yearsOfExperience) &&
		resume && resume.size < (3 * 1000000) && resume.type.indexOf("application/pdf") > -1 &&
		availability && checkValue("availability", availability) &&
		workAuthorization && checkValue("workAuthorization", workAuthorization) &&
		emailId && validator.isEmail(emailId);
};