import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {GetCandidateStatsCount} from "../../../redux/commonStoreAndActions/candidate-action";
import {GetJobStatsCount} from "../../../redux/commonStoreAndActions/stats-actions";
import {APP_URLS} from "../../appUrl";
import {candidateJobApplicationType} from "../candidate-view/candidateMenuOptions";
import {jobStatus} from "../job-post/jobMenuOptions";

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	statsCountContainer: {
		height: "130px",
		width: "130px",
		textAlign: "center",
		border: "solid 1px rgba(0,0,0,0)",
		color: "#fff",
		cursor: "pointer",
		borderRadius: "5px",
		boxShadow: "6px 4px 32px -6px rgba(0,0,0,0.75)"
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 6}px`,
	},
	statsTitle: {
		margin: "32px 0 0 0px",
		textTransform: "uppercase",
		fontWeight: 700
	},
	statusCount: {
		height: "90px",
		alignContent: "center",
		display: "flex",
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		fontSize: 40,
		justifyContent: "center"
	},
	jobStatusText: {
		textTransform: "uppercase",
	},
	c0: {
		backgroundColor: "#09af00",
	},
	c1: {
		backgroundColor: "#021aee",
	},
	c2: {
		backgroundColor: "#b1006a",
	},
	c3: {
		backgroundColor: "#ee6002",
	},
	cd0: {
		backgroundColor: "#09af00",
	},
	cd1: {
		backgroundColor: "#b1006a",
	},
	cd2: {
		backgroundColor: "#021aee",
	},
	cd3: {
		backgroundColor: "#ee6002",
	}
});

class DashboardHome extends Component {
	componentDidMount = () => {
		let {GetJobStatsCount, GetCandidateStatsCount} = this.props;
		for (let i in jobStatus) {
			if (jobStatus.hasOwnProperty(i)) {
				GetJobStatsCount(jobStatus[i].value);
			}
		}
		for (let i in candidateJobApplicationType) {
			if (candidateJobApplicationType.hasOwnProperty(i)) {
				GetCandidateStatsCount(candidateJobApplicationType[i].value);
			}
		}

	};
	getStats = () => {
		const {classes, stats} = this.props;

		return jobStatus.map((item, index) => {
			let count = stats[item.value];
			return (
				<Grid key={index} item>
					<div className={classes.statsCountContainer + " " + classes["c" + index]} onClick={() => {
						this.setState({
							redirectUrl: APP_URLS.VIEW_JOBS.url + "/" + item.value
						});
					}}>
						<div className={classes.statusCount}><span>{count > 100 ? "100 +" : count}</span></div>
						<div className={classes.jobStatusText}>{item.text}</div>
					</div>
				</Grid>
			);
		});
	};

	getCandidateStats = () => {
		const {classes, candidate} = this.props;

		return candidateJobApplicationType.map((item, index) => {
			let count = candidate.stats[item.value] || 0;
			return (
				<Grid key={index} item>
					<div className={classes.statsCountContainer + " " + classes["cd" + index]} onClick={() => {
						this.setState({
							redirectUrl: APP_URLS.CANDIDATE.url + "/" + item.value
						});
					}}>
						<div className={classes.statusCount}><span>{count > 100 ? "100 +" : count}</span></div>
						<div className={classes.jobStatusText}>{item.text}</div>
					</div>
				</Grid>
			);
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null
		};
	}

	render() {
		const {classes} = this.props;
		return (
			<div>
				{this.state.redirectUrl ? <Redirect to={this.state.redirectUrl}/> : null}
				<Typography variant="h4" gutterBottom component="h4">
					Dashboard
				</Typography>
				<Paper className={classes.paper}>
					<Grid container className={classes.root} xs={12} sm={12} direction="row"
					      justify="space-evenly"
					      alignItems="center" spacing={32}>
						<Grid item xs={12} className={classes.statsTitle}>Job Count Status</Grid>
						{this.getStats()}
					</Grid>
				</Paper>
				<Paper className={classes.paper}>
					<Grid container className={classes.root} xs={12} sm={12} direction="row"
					      justify="space-evenly"
					      alignItems="center" spacing={32}>
						<Grid item xs={12} className={classes.statsTitle}>Job Applications</Grid>
						{this.getCandidateStats()}
					</Grid>
				</Paper>
			</div>
		);
	}
}

DashboardHome.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	stats: state.stats,
	candidate: state.candidate
});

export default connect(mapStateToProps, {GetJobStatsCount, GetCandidateStatsCount})(withStyles(styles)(DashboardHome));