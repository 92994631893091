import {TYPES} from "./candidate-action";

export default (state = {stats: {}}, action = {}) => {
	switch (action.type) {
		case TYPES.GET_CANDIDATE_STAT:
			let stats = {stats: {...state.stats, ...action.payload.data}};
			return {...state, ...stats};
		default:
			return state;
	}
}