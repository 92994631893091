const BASE_URL = "/dashboard/v1/api/";
const PUBLIC_URL = "/public/v1/api/";

export const APIs = {
	SIGN_IN: BASE_URL + "auth/sign-in",
	LOG_OUT: BASE_URL + "auth/logout",
	JOB: BASE_URL + "job",
	JOB_LIST: BASE_URL + "job/list",
	JOB_STATUS: BASE_URL + "job/status",
	CLIENT_LIST: BASE_URL + "client-list",
	END_CLIENT_LIST: BASE_URL + "end-client-list",
	JOB_STATS_COUNT: BASE_URL + "job/stats/",
	JOB_CANDIDATE_COUNT: BASE_URL + "candidate/stats/",
	CANDIDATE_LIST: BASE_URL + "candidate/list/",
	CANDIDATE_STATUS: BASE_URL + "candidate/status/",
	MAILING_LIST: BASE_URL + "mail",
	GET_JOB_BY: BASE_URL + "job-by/",
	GET_FEEDBACK_CANDIDATE_ID: BASE_URL + "feedback/",
	GET_PUBLIC_JOB_LIST: PUBLIC_URL + "job-list",
	GET_PUBLIC_JOB_BY_SKILL: PUBLIC_URL + "job-list/search",
	POST_PUBLIC_RESUME: PUBLIC_URL + "resume-post",
	POST_PUBLIC_RESUME_FILE: PUBLIC_URL + "resume-file",
	GET_JOB_DETAILS_PUBLIC: PUBLIC_URL + "job-details-public",
	CONTACT_US_PUBLIC: PUBLIC_URL + "contact-us-public",
	GET_SERVICE_PUBLIC: PUBLIC_URL + "services",
	GET_TERM_OF_USE: PUBLIC_URL + "term-of-use",
	GET_PRIVACY_POLICY: PUBLIC_URL + "privacy-policy",
	GET_ABOUT_US: PUBLIC_URL + "about-us",

};