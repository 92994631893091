import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {isMobile} from "../../utils/detectBrowser";
import {APP_URLS} from "../appUrl";
import AboutUs from "./about-us";
import Footer from "./app-footer";
import Home from "./app-home";
import Career from "./career";
import ContactUs from "./contact-us";
import JobViewPublic from "./job-view-public";
import PrivacyPolicy from "./privacy-policy";
import Services from "./services";
import SignIn from "./sign-in";
import TermsOfUse from "./terms-of-use";

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	csLogoContainer: {
		display: "flex"
	},
	appBar: {
		backgroundColor: "#4a4c4b",
		boxShadow: "0 8px 26px 0 rgba(167, 167, 167, 0.5)"
	},
	logo: {
		height: "30px",
		width: "auto",
		margin: "0 10px 0 0"
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
});

class Main extends Component {
	handleClick = (event) => {
		this.setState({
			anchorEl: event.currentTarget
		});
	};
	handleClose = () => {
		this.setState({
			anchorEl: null
		});
	};
	getContent = () => {
		this.disabledFooterColor = false;
		let {pageKey, jobId, serviceName} = this.props;
		switch (pageKey) {
			case APP_URLS.HOME.key: {
				this.disabledFooterColor = true;
				return (
					<Home/>
				);
			}
			case APP_URLS.CAREER.key: {
				return (
					<Career jobId={jobId}/>
				);
			}
			case APP_URLS.CONTACT_US.key: {
				return (
					<ContactUs/>
				);
			}
			case APP_URLS.ABOUT_US.key: {
				return (
					<AboutUs/>
				);
			}
			case APP_URLS.SIGN_IN.key: {
				return (
					<SignIn/>
				);
			}
			case APP_URLS.TermsOfUse.key: {
				return (
					<TermsOfUse/>
				);
			}
			case APP_URLS.PRIVACY_POLICY.key: {
				return (
					<PrivacyPolicy/>
				);
			}
			case APP_URLS.JOB_VIEW_PUBLIC.key: {
				return (
					<JobViewPublic jobId={jobId}/>
				);
			}
			case APP_URLS.SERVICES.key: {
				return (
					<Services serviceName={serviceName}/>
				);
			}
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};
		this.disabledFooterColor = false;
	}

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.root}>
				<CssBaseline/>
				<AppBar position="static" className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6" color="inherit" className={classes.grow}>
							<Link to={APP_URLS.HOME.url}>
								<div className={classes.csLogoContainer}>
									<img className={classes.logo} src={"/images/logo.png"} alt={"Collabrium Systems"}/>
									<span>Collabrium Systems</span>
								</div>
							</Link>
						</Typography>
						{isMobile.any() ? (
							<Fragment>
								<MoreIcon onClick={this.handleClick}/>
								<Menu id="simple-menu"
								      anchorEl={this.state.anchorEl}
								      open={Boolean(this.state.anchorEl)}
								      anchorOrigin={{
									      vertical: 'top',
									      horizontal: 'right',
								      }}
								      transformOrigin={{
									      vertical: 'top',
									      horizontal: 'right',
								      }}
								      onClose={this.handleClose}>
									<MenuItem onClick={this.handleClose}>
										<Link to={APP_URLS.SERVICES.url}>Services</Link>
									</MenuItem>
									<MenuItem onClick={this.handleClose}>
										<Link to={APP_URLS.CAREER.url}>Career</Link>
									</MenuItem>
									<MenuItem onClick={this.handleClose}>
										<Link to={APP_URLS.CONTACT_US.url}>Contact Us</Link>
									</MenuItem>
									<MenuItem onClick={this.handleClose}>
										<Link to={APP_URLS.SIGN_IN.url}>Login</Link>
									</MenuItem>
								</Menu>
							</Fragment>
						) : (
							<Fragment>
								<Button color="inherit"><Link to={APP_URLS.SERVICES.url}>Services</Link></Button>
								<Button color="inherit"><Link to={APP_URLS.CAREER.url}>Career</Link></Button>
								<Button color="inherit"><Link to={APP_URLS.CONTACT_US.url}>Contact Us</Link></Button>
								<Button color="inherit"><Link to={APP_URLS.SIGN_IN.url}>Login</Link></Button>
							</Fragment>
						)}
					</Toolbar>
				</AppBar>
				<main className={classes.content}>
					{this.getContent()}
				</main>
				<footer>
					<Footer disabledFooterColor={this.disabledFooterColor}/>
				</footer>
			</div>
		);
	}
}

export default withStyles(styles)(Main);