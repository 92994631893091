import axios from "axios";
import React from "react";
import {toast} from "react-toastify";
import {APIs} from "../../api/index";


export const TYPES = {
	GET_FEEDBACK: "GET_FEEDBACK_6",
	POST_FEEDBACK: "POST_FEEDBACK_7",
	DELETE_FEEDBACK: "DELETE_FEEDBACK_8"
};

export const getFeedback = (candidateId) => {
	const request = axios.get(APIs.GET_FEEDBACK_CANDIDATE_ID + candidateId);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_FEEDBACK,
				payload: response
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to get candidate feedback"}</div>);
		});
	}
};

export const postFeedback = (candidateId, feedback, cb) => {
	const request = axios.post(APIs.GET_FEEDBACK_CANDIDATE_ID + candidateId, feedback);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.POST_FEEDBACK,
				payload: response
			});
			toast.info(<div>{"Candidate feedback saved"}</div>);
			cb();
		}).catch((err) => {
			toast.error(<div>{"Unable to save candidate feedback"}</div>);
		});
	}
};

export const deleteFeedback = (feedbackId) => {

};