import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import debounce from "lodash.debounce";
import PropTypes from 'prop-types';
import React, {Component, PureComponent, Fragment} from 'react';
import {AutoSizer, Column, SortDirection, Table} from 'react-virtualized';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	table: {
		fontFamily: theme.typography.fontFamily,
	},
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
	},
	tableRow: {
		cursor: 'pointer',
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
	},
	noClick: {
		cursor: 'initial',
	}
});

class MuiVirtualizedTable extends PureComponent {
	getRowClassName = ({index}) => {
		const {classes, rowClassName, onRowClick} = this.props;

		return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		});
	};

	cellRenderer = ({cellData, columnIndex = null}) => {
		const {columns, classes, rowHeight, onRowClick} = this.props;
		return (
			<TableCell
				component="div"
				className={classNames(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{height: rowHeight}}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}>
				{cellData}
			</TableCell>
		);
	};

	headerRenderer = ({label, columnIndex, dataKey, sortBy, sortDirection}) => {
		const {headerHeight, columns, classes, sort} = this.props;
		const direction = {
			[SortDirection.ASC]: 'asc',
			[SortDirection.DESC]: 'desc',
		};

		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			);

		return (
			<TableCell
				component="div"
				className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{height: headerHeight}}
				align={columns[columnIndex].numeric || false ? 'right' : 'left'}>
				{inner}
			</TableCell>
		);
	};

	render() {
		const {classes, columns, ...tableProps} = this.props;
		return (
				<AutoSizer>
					{({height, width}) => (
						<Table
							className={classes.table}
							height={height}
							width={width}
							{...tableProps}
							rowClassName={this.getRowClassName}>
							{columns.map(({cellContentRenderer = null, className, dataKey, ...other}, index) => {
								let renderer;
								if (cellContentRenderer != null) {
									renderer = cellRendererProps =>
										this.cellRenderer({
											cellData: cellContentRenderer(cellRendererProps),
											columnIndex: index,
										});
								} else {
									renderer = this.cellRenderer;
								}

								return (
									<Column
										key={dataKey}
										headerRenderer={headerProps =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index,
											})
										}
										className={classNames(classes.flexContainer, className)}
										cellRenderer={renderer}
										dataKey={dataKey}
										{...other}/>
								);
							})}
						</Table>
					)}
				</AutoSizer>
		);
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
		}),
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
	sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
const PAGE_SIZE = 50;
class ReactVirtualizedTable extends Component {
	resetTable = () => {
		this.setState({
			count: 0,
			page: {}
		});
		this.getRowData({index: 0});
	};
	componentDidMount = () => {
		this.getRowData({index: 0});
	};
	onDataFetched = (data) => {
		let page = this.state.page,
			pageNo = data.pageNo;
		page[pageNo] = data.rows;
		for (let key in page) {
			if (page.hasOwnProperty(key)) {
				let numKey = Number(key);
				if (!((numKey === (pageNo - 1)) || (numKey === pageNo) || (numKey === (pageNo + 1)))) {
					delete page[key];
				}
			}
		}
		this.setState({
			count: data.count,
			page: page
		});
	};
	fetchRowData = (currentPage) => {
		let {getTableData, search} = this.props;
		let payLoad = {
			skip: currentPage * PAGE_SIZE,
			limit: PAGE_SIZE,
			pageNo: currentPage
		};
		if (search && this.searchText) {
			payLoad.searchText = this.searchText;
		}
		else if (search) {
			payLoad.searchText = "_NO_SEARCH_"
		}
		getTableData(payLoad, this.onDataFetched);
	};
	getRowData = (params) => {
		let index = params ? params.index : 0,
			currentPage = parseInt(index / PAGE_SIZE),
			currentPageData = this.state.page[currentPage];
		if (currentPageData) {
			/*let mod = index % PAGE_SIZE,
				nextPage = currentPage + 1,
				previousPage = currentPage - 1,
				percentScroll = mod / PAGE_SIZE,
				totalPages = 5;
			if (!this.state.page[previousPage] && percentScroll > 0.85 && nextPage < totalPages) {
				this.getTableDataDebounce(currentPage + 1, 200);
			}
			else if (!this.state.page[previousPage] && percentScroll < 0.15 && (previousPage > -1)) {
				this.getTableDataDebounce(previousPage, 200);
			}*/

			return currentPageData[index % PAGE_SIZE];
		}
		else {
			this.getTableDataDebounce(currentPage);
			return {};
		}
	};

	handleChange = (e) => {
		this.setState({
			count: 0,
			page: {}
		});
		this.fetchRowData(0);
	};

	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			page: {}
		};
		this.getTableDataDebounce = debounce(this.fetchRowData, 100);
		this.getTableDataSearchDebounce = debounce(this.handleChange, 300);
	}

	render() {
		let {config, search, rowHeight = 56, headerHeight = 56, classes} = this.props,
			{count} = this.state;
		return (
			<Fragment>
				{search && (
					<div className={"tableSearchContainer"}>
						<TextField
							id="job-search"
							label="Search candidate by skill"
							value={this.state.search}
							onChange={(e)=> {
								this.searchText = e.target.value;
								this.getTableDataSearchDebounce(e);
							}}
							margin="normal"
						/>
					</div>
				)}
				<Paper style={{height: "100%", width: '100%'}}>
					<WrappedVirtualizedTable
						rowCount={count}
						rowHeight={rowHeight}
						headerHeight={headerHeight}
						rowGetter={this.getRowData}
						onRowClick={event => console.log(event)}
						columns={config}/>
				</Paper>
			</Fragment>
		);
	}
}

export default ReactVirtualizedTable;//withStyles(styleReactVT)(ReactVirtualizedTable);