import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, {Component} from "react";
import {candidateJobApplicationTypeChangeTo}  from "./candidateMenuOptions";

class ChangeStatus extends Component {
	handleChange = (e) => {
		this.props.changeResumeStatus({
			_id: this.props.rowData._id,
			status: e.target.value
		});
	};
	getMenu = () => {
		let menus = [];
		candidateJobApplicationTypeChangeTo.map((item, index) => {
			menus.push(<MenuItem value={item.value} key={index}>{item.text}</MenuItem>);
		});
		return menus;
	};

	constructor(props) {
		super(props);
	}

	render() {
		console.log(this.props.rowData.jobStatus);
		return (
			<div>
				<Select
					value={this.props.status}
					onChange={(e) => {
						this.handleChange(e)
					}}
					inputProps={{
						name: 'resumeStatusChange',
						id: 'resume-status',
					}}>
					{this.getMenu()}
				</Select>
			</div>
		)
	}
}

export default ChangeStatus;