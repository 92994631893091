import React from "react";
import {isMobile} from "../../../utils/detectBrowser";
import {getCandidateSkillText} from "../../non-auth-pages/job-view-public/resumeUploadOptions";
import Feedback from "./feedbackDialog";
import MobileView from "./mobileView";
import Resume from "./resumeViewDialog";
import ChangeStatus from "./changeStatus";

export const config = (status, changeResumeStatus) => {
	if (isMobile.any()) {
		return [{
			flexGrow: 1.0,
			width: 500,
			headerStyle: {
				height: 0
			},
			cellContentRenderer: (props) => {
				return (
					<MobileView {...props} status={status} changeResumeStatus={changeResumeStatus}/>
				);
			}
		}];
	}
	else {
		return [{
			width: 300,
			flexGrow: 1.0,
			label: 'Candidate Name',
			cellContentRenderer: (props) => {
				return (
					<div>{props.rowData.firstName + " " + props.rowData.lastName}</div>
				);
			}
		}, {
			width: 300,
			flexGrow: 1.0,
			label: 'Skills',
			cellContentRenderer: (props) => {
				const skills = props.rowData.skills ? props.rowData.skills.split(",") : [];
				return skills.map((skill, index) => {
					return (
						<span key={index} style={{margin: "0 10px 0 0"}}>
							{getCandidateSkillText(skill) + ((index + 1) === skills.length ? "" : ",")}
						</span>
					);
				});
			}
		}, {
			width: 200,
			label: 'Supplier Name',
			dataKey: 'supplierName'
		}, {
			label: 'Change Status',
			width: 200,
			cellContentRenderer: (props) => {
				return (
					<ChangeStatus {...props} changeResumeStatus={changeResumeStatus} status={status}/>
				);
			}
		}, {
			width: 200,
			label: 'Feedback',
			cellContentRenderer: (props) => {
				return (
					<Feedback rowData={props.rowData}/>
				);
			}
		}, {
			width: 200,
			label: 'Resume',
			cellContentRenderer: (props) => {
				return (
					<Resume rowData={props.rowData}/>
				);
			}
		}];
	}
};