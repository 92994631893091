import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, {Component} from "react";
import {Redirect} from "react-router";
import Slider from "react-slick";
import {isMobile} from "../../../utils/detectBrowser";
import {APP_URLS} from "../../appUrl";

const styles = theme => ({
	head: {
		fontSize: "18px",
		margin: "0 0 20px",
		textAlign: "center",
		color: "#414141"
	},
	message: {
		color: "#6c6c6c",
		textAlign: "center",
		lineHeight: "1.75",
		fontSize: "16px",
	},
	customer: {
		textAlign: "center",
		fontSize: "36px",
		margin: "40px 0",
		[theme.breakpoints.down('sm')]: {
			fontSize: "2rem",
		}
	},
	customerSlider: {
		height: "348px",
		position: "relative",
		padding: "20px",
		"&>img": {
			height: "330px",
			width: "100%",
			borderRadius: "4px",
		}
	},
	oddMessage: {
		position: "absolute",
		left: "50px",
		right: "50px",
		bottom: "30px",
		color: "#fff",
		"&>div:first-child": {
			fontSize: "16px",
			margin: "0 0 10px"
		}
	},
	evenMessage: {
		position: "absolute",
		left: "50px",
		top: "50px",
		right: "50px",
		color: "#fff",
		"&>div:first-child": {
			fontSize: "16px",
			margin: "0 0 10px"
		}
	},
	knowMoreOdd: {
		width: "100px",
		display: "grid",
		gridTemplateColumns: "auto 20px",
		alignItems: "center",
		position: "absolute",
		right: "50px",
		top: "50px",
		color: "#fff",
	},
	knowMoreEven: {
		width: "100px",
		display: "grid",
		gridTemplateColumns: "auto 20px",
		alignItems: "center",
		position: "absolute",
		right: "50px",
		bottom: "50px",
		color: "#fff",
	},
	oval: {
		width: "18px",
		height: "18px",
		backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
		content: " ",
		borderRadius: "50%"
	},
	sliderContainer: {
		width: "80vw",
		maxWidth: "1200px",
		margin: "0 auto",
		height: "450px"
	},
	sliderContainerMobile: {
		margin: "0 -40px",
	},
	phySection: {
		backgroundColor: "#f6f7f9",
		padding: "60px 0"
	},
	why: {
		color: "#626262"
	},
	phy: {
		color: "#2c2c2c",
		fontSize: "32px"
	},
	phyMessage1: {
		color: "#2c2c2c",
		margin: "0 0 20px",
		lineHeight: "1.55",
		fontSize: "16px",
	},
	phyMessage2: {
		color: "#626262",
		fontSize: "12px",
	},
	getInTouch: {
		margin: "20px -20px 0",
		padding: "60px 0",
		color: "#fff",
		backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
		textAlign: "center"
	},
	getInTouchHead: {
		fontSize: "55px",
		[theme.breakpoints.down('sm')]: {
			fontSize: "2rem",
		}
	},
	contactUsButton: {
		borderRadius: "4px",
		backgroundColor: "#ffffff",
		fontSize: "18px",
		color: "#464646",
		padding: "10px 30px",
		'&:hover': {
			background: '#fff'
		}
	},
	bulb: {
		height: "32px",
		width: "32px",
		backgroundImage: "url(/images/bulb.png)",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		margin: "0 auto 20px"
	},
	clientSay: {
		margin: "5rem -2rem 1rem",
		textAlign: "center",
		fontSize: "2rem",
		color: "#000"
	},
	customerSpeakContainer: {
		backgroundColor: "#f6f7f9",
		margin: "0 -2rem 2rem",
	},
	customerSpeak: {
		width: "50%",
		margin: "0 25%",
		[theme.breakpoints.up('xs')]: {
			width: "90%",
			margin: "0 5%",
		},
	},
	customerContainer: {
		padding: "3rem",
		[theme.breakpoints.up('xs')]: {
			padding: "1rem",
		},
		"&>div:first-child": {
			color: "#454545",
			textAlign: "left",
			opacity: 0.7,
			margin: "0 0 1rem",
			fontStyle: "italic"
		}
	}
});

class NextArrow extends Component {
	render() {
		let {className, style, onClick} = this.props;
		className = className.replace("slick-next", "");
		return (
			<div
				className={className}
				style={{
					...style,
					width: "63px",
					height: "63px",
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					left: "-100px",
					top: "50%",
					cursor: "pointer",
					color: "#fff",
					backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)"
				}}
				onClick={onClick}>
				<ArrowLeft/>
			</div>
		);
	}
}

class PrevArrow extends Component {
	render() {
		let {className, style, onClick} = this.props;
		className = className.replace("slick-prev", "");
		return (
			<div
				className={className}
				style={{
					...style,
					width: "63px",
					height: "63px",
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					right: "-100px",
					top: "50%",
					cursor: "pointer",
					color: "#fff",
					backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)"
				}}
				onClick={onClick}>
				<ArrowRight/>
			</div>
		);
	}
}

class BusinessVerticalsAndCustomer extends Component {
	contactUs = () => {
		this.setState({
			redirectToContactUs: true
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			redirectToContactUs: false
		};
	}

	render() {
		const isSmDevice = isMobile.any(),
			{classes} = this.props,
			settings = isSmDevice ? {
				dots: false,
				infinite: true,
				speed: 500,
				autoplaySpeed: 3000,
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				arrows: false
			} : {
				dots: false,
				infinite: false,
				lazyLoad: true,
				speed: 500,
				centerMode: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 1,
				nextArrow: <NextArrow/>,
				prevArrow: <PrevArrow/>
			};
		return (
			<div>
				{this.state.redirectToContactUs ? (
					<Redirect to={APP_URLS.CONTACT_US.url}/>
				) : null}
				<Grid container spacing={40} justify="center">
					<Grid item xs={12} sm={3}>
						<div>
							<div className={classes.bulb}/>
						</div>
						<div className={classes.head}>IT Solution Delivery</div>
						<div className={classes.message}>
							Founded and managed by individuals with decades of software development and architecture experience, we know IT solution delivery.  We partner with clients to provide the right people, process, and strategy for successful, cost-effective solutions.
						</div>
					</Grid>
					<Grid item xs={12} sm={3}>
						<div>
							<div className={classes.bulb}/>
						</div>
						<div className={classes.head}>IT Staffing</div>
						<div className={classes.message}>
							We outperform the market in the talent per dollar equation by combining high quality technical screening with a cost-competitive approach.
						</div>
					</Grid>
					<Grid item xs={12} sm={3}>
						<div>
							<div className={classes.bulb}/>
						</div>
						<div className={classes.head}>IT Maintenance & Support</div>
						<div className={classes.message}>
							Maintenance and support require a different approach and skill set to solution delivery.  We provide application and infrastructure maintenance and support services to maintain the health of your valuable IT assets.
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.customer}>Value to our customers</div>
					</Grid>
					<Grid item xs={12}>
						<div className={isMobile.any() ? classes.sliderContainerMobile : classes.sliderContainer}>
							<Slider {...settings}>
								<div>
									<div className={classes.customerSlider}>
										<img src={"/images/hands.png"}/>
										<div className={classes.oddMessage}>
											<div>Technical screening process performed by technology professionals. Low-cost, low overhead cost model.</div>
										</div>
									</div>
								</div>
								<div>
									<div className={classes.customerSlider}>
										<img src={"/images/1.png"}/>
										<div className={classes.oddMessage}>
											<div>Our deep talent pool of technical architects and technology leaders drive agile, innovative solution delivery.</div>
										</div>
									</div>
								</div>
								<div>
									<div className={classes.customerSlider}>
										<img src={"/images/IT_Delivery_2.png"}/>
										<div className={classes.evenMessage}>
											<div>Maintenance and support of your critical applications and IT Infrastructure.</div>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</Grid>
					<Grid container spacing={40} justify="center" className={classes.phySection}>
						<Grid item xs={12} sm={3}>
							<div className={classes.why}>WHY US?</div>
							<div className={classes.phy}>Our Philosophy as an Employer
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div className={classes.phyMessage1}>
								Collabrium provides a work environment where employees are happy and have the opportunity to perform rewarding work.  We build relationships with employees based on integrity and mutual respect.  We aim to offer highly competitive compensation.  Our senior staff in various technical competencies are always happy to mentor other employees; additionally, we encourage and facilitate peer-to-peer collaboration and support across the company to help all of our employees achieve high performance.
							</div>
						</Grid>
					</Grid>
					<div className={classes.clientSay}>What Our Clients Say</div>
					<div className={classes.customerSpeakContainer}>
						<Grid container spacing={40} justify="center" className={classes.customerSpeak}>
							<Grid item xs={12} sm={6}>
								<div className={classes.customerContainer}>
									<div>"For years, Collabrium has been our go-to supplier for top-notch, long-term
										talent. Their focus on thorough prescreening saves my teams time in finding the
										best resources, and their long-term mindset and cost-competitive approach has
										allowed us to meet the needs of our most demanding clients."
									</div>
									<div>
										<div>-Large PA Application Management Services Provider.</div>
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className={classes.customerContainer}>
									<div>
										"Collabrium has partnered with OST for the past 3.5 years in the PA IT Staff
										Augmentation program, and has consistently provided qualified, quality
										candidates to fill challenging IT positions at the Commonwealth."
									</div>
									<div>
										<div>-OST, Inc.</div>
										<div>MSP Manager</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					<Grid container spacing={40} justify="center" className={classes.getInTouch}>
						<Grid item className={classes.getInTouchHead} xs={12}>
							Let’s get in touch!
						</Grid>
						<Grid item xs={12}>
							<Button className={classes.contactUsButton} onClick={this.contactUs}>Contact Us</Button>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(BusinessVerticalsAndCustomer);