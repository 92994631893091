import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, {Component} from "react";
import * as MenuOptions from "../job-post/jobMenuOptions";
import {jobStatus} from "../job-post/jobMenuOptions";

class ChangeStatus extends Component {
	handleChange = (e) => {
		this.props.onJobStatusChange({
			_id: this.props.rowData._id,
			jobStatus: e.target.value
		});
	};
	getMenu = (field) => {
		let menus = [];
		MenuOptions[field].map((item, index) => {
			menus.push(<MenuItem value={item.value} key={index}>{item.text}</MenuItem>);
		});
		return menus;
	};

	constructor(props) {
		super(props);
	}

	render() {
		console.log(this.props.rowData.jobStatus);
		return (
			<div>
				<Select
					value={this.props.status}
					onChange={(e) => {
						this.handleChange(e)
					}}
					inputProps={{
						name: 'jobStatus',
						id: 'job-status',
					}}>
					{this.getMenu("jobStatus")}
				</Select>
			</div>
		)
	}
}

export default ChangeStatus;