import axios from "axios";
import {APIs} from "../../api/index";

export const TYPES = {
	GET_JOB_STAT: "GET_JOB_STAT_19"
};

export const GetJobStatsCount = (status) => {
	const request = axios.get(APIs.JOB_STATS_COUNT + status);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_JOB_STAT,
				payload: response
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};
