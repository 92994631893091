import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/core/styles/index";
import React, {Component} from "react";
import ChangeStatus from "./changeStatus";
import Edit from "./editJob";

const styles = theme => ({
	rowContainer: {
		width: '100%',
		display: 'block', // Fix IE 11 issue.
		flexGrow: 1
	},
	dataRow: {
		margin: "10px 0 10px 0"
	},
	fieldTitle: {
		fontWeight: 700,
		margin: "0 0 5px"
	},
	actionContainer: {
		display: "inline",
		width: "50%",
		float: "left",
		padding: "10px 0 0"
	}
});

class MobileView extends Component {
	getClientName = () => {
		let {client, rowData} = this.props,
			clientName = "-";
		for (let i in client) {
			if (client.hasOwnProperty(i) && rowData.clientName === client[i]._id) {
				clientName = client[i].clientName;
				break;
			}
		}
		return clientName;
	};
	getEndClientName = () => {
		let {endClient, rowData} = this.props,
			endClientName = "-";
		for (let i in endClient) {
			if (endClient.hasOwnProperty(i) && rowData.endClientName === endClient[i]._id) {
				endClientName = endClient[i].endClientName;
				break;
			}
		}
		return endClientName;
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let {classes, onJobStatusChange, status} = this.props;
		let {submissionCloseDate, jobPosition, jobLocation} = this.props.rowData;
		return (
			<div className={classes.rowContainer}>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Job Position:</div>
					<div>{jobPosition}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Client Name:</div>
					<div>{this.getClientName()}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>End Client Name:</div>
					<div>{this.getEndClientName()}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Submission Close Date:</div>
					<div>{submissionCloseDate}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Job Location:</div>
					<div>{jobLocation}</div>
				</div>
				<Divider/>
				<div>
					<div className={classes.actionContainer}>
						<ChangeStatus {...this.props} onJobStatusChange={onJobStatusChange} status={status}/>
					</div>
					<div className={classes.actionContainer}>
						<Edit {...this.props}/>
					</div>

				</div>
			</div>
		);
	}
}

export default (withStyles(styles)(MobileView));