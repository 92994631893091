import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from "react";
import BusinessVerticals from "./businessVerticalsAndCustomer";
import HomeCarousel from "./home-carousel";

const styles = theme => ({
	homeContainer: {
		overflow: "hidden"
	},

	carouselContainer: {
		textAlign: 'center',
		backgroundColor: "#fff"
	},
	growContainer: {
		padding: "40px 40px 60px",
		backgroundColor: "#fff",
	}
});

class Home extends Component {

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.homeContainer}>
				<Grid container spacing={0} justify="center">
					<Grid item xs={12}>
						<div className={classes.carouselContainer}>
							<HomeCarousel/>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.growContainer}>
							<BusinessVerticals/>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(Home);