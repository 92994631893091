import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {APP_URLS} from "../../appUrl";

const styles = theme => (
	{
		footer: {
			margin: "40px 0 0",
			color: "#464646",
			textAlign: "center",
			backgroundColor: "#fff",
			"& > div": {
				padding: "20px",
				"& > div": {
					"& > div": {
						padding: "20px 0",
					}
				}
			}
		}
	}
);

class Footer extends Component {
	render() {
		let {classes} = this.props;
		return (
			<div>
				<Grid container spacing={0} justify="center" className={classes.footer}>
					<Grid item xs={12} style={{fontWeight: 700, fontSize: "18px"}}>Collabrium Systems LLC</Grid>
					<Grid item xs={12} xs={10}>
						<Grid container spacing={10} justify="center">
							<Grid item xs={4} sm={2}><Link to={APP_URLS.ABOUT_US.url}>ABOUT US</Link></Grid>
							<Grid item xs={4} sm={2}><Link to={APP_URLS.SERVICES.url}>SERVICES</Link></Grid>
							<Grid item xs={4} sm={2}><Link to={APP_URLS.CONTACT_US.url}>CONTACT US</Link></Grid>
						</Grid>
					</Grid>
					<div style={!this.props.disabledFooterColor ? {
						backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
						color: "#fff",
						width: "100%"
					} : {width: "100%"}}>
						<Grid item xs={12} sm={10}>
							<Grid container spacing={10} justify="center">
								<Grid item xs={4} sm={7}>{`Copyright © Collabrium Systems LLC ${(new Date()).getFullYear()} All Right Reserved`}</Grid>
								<Grid item xs={4} sm={2}><Link to={APP_URLS.PRIVACY_POLICY.url}>Privacy
									Policy</Link></Grid>
								<Grid item xs={4} sm={2}><Link to={APP_URLS.TermsOfUse.url}>Terms Of
									Services</Link></Grid>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(Footer);