import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles/index";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {GetServiceInfo} from "../../../redux/commonStoreAndActions/service-actions";

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	tabsRoot: {
		borderBottom: "none"
	},
	tabsIndicator: {
		backgroundColor: "#fe52a3",
		height: "4px"
	},
	tabRoot: {
		textTransform: "initial",
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing.unit * 4,
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(","),
		"&:hover": {
			color: "#fe52a3",
			opacity: 1
		},
		"&$tabSelected": {
			color: "#fe52a3 !important",
			fontWeight: theme.typography.fontWeightMedium
		},
		"&:focus": {
			color: "#fe52a3"
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: "0.8rem"
		}
	},
	tabSelected: {},
	servicePageImg: {
		position: "relative"
	},
	pageImage: {
		width: "100%"
	},
	pageTitleText: {
		fontSize: "2rem",
		lineHeight: 1.34,
		letterSpacing: "normal",
		color: "#ffffff",
		zIndex: 2,
		position: "absolute",
		top: "calc(50vh - 80px)",
		width: "calc(100% - 6rem)",
		left: "3rem",
		[theme.breakpoints.down('sm')]: {
			top: "2rem",
			width: "96vw",
			left: "2vw",
			fontSize: "1rem",
		}
	},
	serviceDetails: {
		padding: "0 3rem 3rem"
	},
	whyText: {
		color: "#626262",
		letterSpacing: "-0.1px",
		lineHeight: "1.55",
		fontSize: "1rem",
		margin: "3rem"
	},
	divide: {
		borderBottom: "solid 2px #2c2c2c",
		marginBottom: "3rem"
	},
	serviceText: {
		color: "#626262",
		padding: "0 2.85rem"
	},
	pointContainer: {
		backgroundColor: "#fbfbfb",
		margin: "3rem 0 0",
		padding: "0 3rem"
	},
	pointImg: {
		"&>img": {
			height: "2rem",
		},
		margin: "2rem 1rem "
	},
	pointTitle: {
		textTransform: "uppercase",
		letterSpacing: "0.4px",
		color: "#2c2c2c"
	},
	points: {
		color: "#626262",
		fontSize: "0.8rem"
	}
});

const TabContainer = (props) => {
	return (
		<Typography component="div">
			{props.children}
		</Typography>
	);
};

const TAB_VALUES = {
	IT_STAFFING: "it-staffing",
	IT_SOLUTION_DELIVERY: "it-solution-delivery",
	IT_MAINTENANCE_AND_SUPPORT: "it-maintenance-and-support"
};

const TAB_V = {
	"it-staffing": 0,
	"it-solution-delivery": 1,
	"it-maintenance-and-support": 2
};

class Services extends Component {

	componentDidMount = () => {
		let {
			GetServiceInfo,
			serviceName
		} = this.props;
		GetServiceInfo(serviceName);
	};

	componentWillUpdate = (prevProps) => {
		if (this.props.serviceName !== prevProps.serviceName && !this.props.serviceData[prevProps.serviceName]) {
			this.props.GetServiceInfo(prevProps.serviceName);
		}
	};

	render() {
		let {
			classes,
			serviceName = TAB_VALUES.IT_STAFFING,
			serviceData = {}
		} = this.props;
		let data = serviceData[serviceName];

		return (
			<div className={classes.root}>
				<Tabs
					value={TAB_V[serviceName]}
					onChange={(v) => {
						console.log(v);
					}}
					classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
					centered>
					<Link to={"/services/" + TAB_VALUES.IT_STAFFING}>
						<Tab label="IT STAFFING"
						     value={0}
						     classes={{root: classes.tabRoot, selected: classes.tabSelected}}/>
					</Link>
					<Link to={"/services/" + TAB_VALUES.IT_SOLUTION_DELIVERY}>
						<Tab label="IT SOLUTION DELIVERY"
						     value={1}
						     classes={{root: classes.tabRoot, selected: classes.tabSelected}}/>
					</Link>
					<Link to={"/services/" + TAB_VALUES.IT_MAINTENANCE_AND_SUPPORT}>
						<Tab label="IT MAINTENANCE AND SUPPORT"
						     value={2}
						     classes={{root: classes.tabRoot, selected: classes.tabSelected}}/>
					</Link>
				</Tabs>
				{data ? (
					<Typography className={classes.typography}>
						<TabContainer>
							<div>
								<div className={classes.servicePageImg}>
									<img src={data.pageImg} className={classes.pageImage}/>
									<div className={classes.pageTitleText}>{data.title}</div>
								</div>
								<div className={classes.serviceDetails}>
									<div className={classes.whyText}>{data.whyText}</div>
									<div className={classes.divide}/>
									<div className={classes.serviceText}>
										{data.text && data.text.map((text, index) => {
											return (
												<p key={index}>{text}</p>
											);
										})}
									</div>
									<div className={classes.pointContainer}>
										<Grid container spacing={40}>
											{data.points.map((point) => {
												return (
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<div className={classes.pointImg}>
															<img src={point.icon}/>
														</div>
														<div className={classes.pointTitle}>{point.title}</div>
														<div className={classes.points}>
															{point.text.map((t, i) => {
																return (
																	<p key={i}>{t}</p>
																);
															})}
															{point.li ? (
																<ul>
																	{point.li.map((item) => {
																		return (
																			<li>{item}</li>
																		);
																	})}
																</ul>
															) : null}
														</div>
													</Grid>
												);
											})}
										</Grid>
									</div>
								</div>
							</div>
						</TabContainer>
					</Typography>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	serviceData: state.service
});
export default connect(mapStateToProps, {GetServiceInfo})(withStyles(styles)(Services));
