export const jobType = [{
	text: "W2 or C2C", value: "w2_c2c"
}, {
	text: "W2 Only", value: "w2_only"
}];

export const getJobType = (value) => {
	let returnValue = "-";
	for (let i in jobType) {
		if (jobType.hasOwnProperty(i) && jobType[i].value === value) {
			returnValue = jobType[i].text;
			break;
		}
	}
	return returnValue;
};

export const getJobDuration = (value) => {
	let returnValue = "-";
	for (let i in jobDuration) {
		if (jobDuration.hasOwnProperty(i) && jobDuration[i].value === value) {
			returnValue = jobDuration[i].text;
			break;
		}
	}
	return returnValue;
};

export const jobDuration = [{
	text: "3 +", value: 3
}, {
	text: "6 +", value: 6
}, {
	text: "9 +", value: 9
}, {
	text: "12 +", value: 12
}, {
	text: "18 +", value: 18
}, {
	text: "24 +", value: 24
}, {
	text: "30 +", value: 30
}, {
	text: "36 +", value: 36
}, {
	text: "48 +", value: 48
}, {
	text: "60 +", value: 60
}, {
	text: "Long Term", value: 1000
}];

export const interviewType = [{
	text: "Video Call", value: "video_call"
}, {
	text: "Voice Call", value: "voice_call"
}, {
	text: "Face To Face", value: "face_to_face"
}];

export const getInterviewName = (value) => {
	let returnValue = "-";
	for (let i in interviewType) {
		if (interviewType.hasOwnProperty(i) && interviewType[i].value === value) {
			returnValue = interviewType[i].text;
			break;
		}
	}
	return returnValue;
};

export const minExperienceRequired = [{
	text: "1", value: 1
}, {
	text: "2", value: 2
}, {
	text: "3", value: 3
}, {
	text: "4", value: 4
}, {
	text: "5 +", value: 5
}, {
	text: "6 +", value: 6
}, {
	text: "10 +", value: 10
}, {
	text: "15 +", value: 15
}];

export const workAuthorization = [{
	text: "Any Valid Work Permit", value: "any_permit"
}, {
	text: "Citizen and GC", value: "citizen_gc"
}, {
	text: "No OPT", value: "no_opt"
}];

export let clientName = [];

export const setClientName = (data) => {
	let list = [];
	for (let i in data) {
		if (data.hasOwnProperty(i)) {
			list.push({
				text: data[i].clientName,
				value: data[i]._id
			});
		}
	}
	clientName = list;
};

export let endClientName = [];

export const setEndClientName = (data) => {
	let list = [];
	for (let i in data) {
		if (data.hasOwnProperty(i)) {
			list.push({
				text: data[i].endClientName,
				value: data[i]._id
			});
		}
	}
	endClientName = list;
};

export const jobStatus = [{
	text: "Open", value: "open"
}, {
	text: "Filled", value: "filled"
}, {
	text: "Cancelled", value: "cancelled"
}, {
	text: "Pending", value: "pending"
}];

export const tags = [{
	text: "Java", id: "1",
}, {
	text: ".Net", id: "2",
}, {
	text: "C++", id: "3",
}, {
	text: "C", id: "4",
}, {
	text: "Business Analyst", id: "5",
}, {
	text: "Quality Analyst", id: "6",
}, {
	text: "SCRUM Master", id: "6",
}];

export const categories = [{
	text: "Employee", value: "emp"
}, {
	text: "Contractor", value: "sub_contractor"
}, {
	text: "Vendor", value: "vendor"
}, {
	text: "Others", value: "oth"
}];

export const getCategoryName = (value) => {
	let returnValue = "-";
	for (let i in categories) {
		if (categories.hasOwnProperty(i) && categories[i].value === value) {
			returnValue = categories[i].text;
			break;
		}
	}
	return returnValue;
};