import {TYPES} from "./mail-actions";

export default (state = {
	mailingList: [],
	endClientList: []
}, action = {}) => {
	switch (action.type) {
		case TYPES.GET_MAILING_LIST:
			return {...state, mailingList: action.payload.data};
		default:
			return state;
	}
}