import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {APIs} from "../../api/index";

export const TYPES = {
	ADD_NEW_EMAIL: "ADD_NEW_EMAIL_16",
	GET_REMOVE_MAIL: "GET_REMOVE_MAIL_17"
};

export const RemoveFromMailingList = (data, cb) => {
	const request = axios.put(APIs.MAILING_LIST, data);
	return (dispatch) => {
		request.then((response) => {
			cb();
			toast.info(<div>{"Successfully removed email: " + data.email}</div>);
		}).catch((err) => {
			toast.error(<div>{"Unable to remove email: " + data.email}</div>);
		});
	}
};

export const AddNewEmail = (data, callback) => {
	const request = axios.post(APIs.MAILING_LIST, data);
	return (dispatch) => {
		request.then((response) => {
			callback();
			toast.info(<div>{"Successfully add email: " + data.email}</div>);
		}).catch((err) => {
			toast.error(<div>{"Unable to add email: " + data.email}</div>);
		});
	}
};

export const GetMailingList = (params, callback) => {
	const request = axios.get(APIs.MAILING_LIST + "/list/" + params.skip + "/" + params.limit);
	return (dispatch) => {
		request.then((response) => {
			let pageNo = params.pageNo;
			callback({...response.data, pageNo});
		}).catch((err) => {
			toast.error(<div>{"Unable to fetch mail list pageNo.: " + params.pageNo}</div>);
		});
	}
};