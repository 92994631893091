import axios from "axios";
import React, {Component} from "react";

class Loader extends Component {
	setLoader = () => {
		this.setState({
			displayLoader: !this.state.displayLoader
		});
	};
	componentDidMount = () => {
		axios.interceptors.request.use((config) => {
			this.setLoader();
			return config;
		}, (error) => {
			this.setLoader();
			return Promise.reject(error);
		});
		axios.interceptors.response.use((response) => {
			this.setLoader();
			return response;
		}, (error) => {
			this.setLoader();
			return Promise.reject(error);
		});
	};
	render = () => {
		return (
			this.state.displayLoader ? (
				<div className={"loadingContainer"}>
					<div className={"loading"}/>
				</div>
			) : null
		);
	}

	constructor(props) {
		super(props);
		this.state = {
			displayLoader: false
		};
	}
}

export default Loader;