import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from "react-redux";
import {AddNewEmail, GetMailingList, RemoveFromMailingList} from "../../../redux/commonStoreAndActions/mail-actions";
import Table from "../../../ui-common/table";
import {isMobile} from "../../../utils/detectBrowser";
import AddNewEmailDialog from "./addNewEmailInMailingListDialog";
import {config} from "./tableConfig";

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	fab: {
		margin: theme.spacing.unit,
	},
	title: {
		display: 'inline',
		margin: "0 20px 0 0",
	},
	tableContainer: {
		height: "calc(100vh - 195px)"
	}
});

class MailingList extends Component {

	getTableData = (data, callback) => {
		this.props.GetMailingList(data, callback);
	};
	openCloseNewEmailDialog = () => {
		this.setState({
			displayNewEmailDialog: !this.state.displayNewEmailDialog
		});
	};
	addNewEmailToList = (name, email, category) => {
		this.props.AddNewEmail({name, email, category}, () => {
			this.resetTable();
		});
		this.openCloseNewEmailDialog();
	};
	removeFromMailingList = (_id, email) => {
		this.props.RemoveFromMailingList({_id, email}, () => {
			this.resetTable();
		});
	};
	resetTable = () => {
		this.table.resetTable();
	};

	constructor(props) {
		super(props);
		this.state = {
			displayNewEmailDialog: false
		};
	}

	render() {
		let {classes} = this.props;
		return (
			<div className={classes.tableContainer}>
				{this.state.displayNewEmailDialog ? (
					<AddNewEmailDialog closeDialog={this.openCloseNewEmailDialog} onSubmit={this.addNewEmailToList}/>
				) : null}
				<Typography variant="h4" gutterBottom component="h4">
					<div className={classes.title}>Mailing List</div>
					<Fab color="primary" aria-label="Add" className={classes.fab}>
						<AddIcon onClick={this.openCloseNewEmailDialog}/>
					</Fab>
				</Typography>
				<Table
					ref={(ref) => {
						this.table = ref;
					}}
					rowHeight={isMobile.any() ? 150 : 56}
					headerHeight={isMobile.any() ? 0 : 56}
					getTableData={this.getTableData}
					config={config(this.removeFromMailingList)}/>
			</div>
		);
	}
}

MailingList.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	mailList: state.mailList
});

export default connect(mapStateToProps, {
	GetMailingList, AddNewEmail, RemoveFromMailingList
})(withStyles(styles)(MailingList));