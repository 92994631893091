import {convertFromRaw, Editor, EditorState} from "draft-js";
import React, {Component} from "react";

class DraftView extends Component {
	constructor(props) {
		super(props);
		const rawContentFromStore = convertFromRaw(this.props.comment);
		const initialEditorState = EditorState.createWithContent(rawContentFromStore);
		this.state = {
			initialEditorState: initialEditorState
		};
	}

	render() {

		return (
			<Editor editorState={this.state.initialEditorState} readOnly/>
		);
	}
}

export default DraftView;