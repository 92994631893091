import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {APIs} from "../../api/index";

export const TYPES = {
	GET_TERM_OF_USE: "GET_TERM_OF_USE_3",
	GET_PRIVACY_POLICY: "GET_PRIVACY_POLICY_4",
	GET_ABOUT_US: "GET_ABOUT_US_5"
};

export const GetTermsOfUse = () => {
	const request = axios.get(APIs.GET_TERM_OF_USE);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_TERM_OF_USE,
				payload: response
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to get terms of use document"}</div>);
		});
	}
};

export const GetPrivacyPolicy = () => {
	const request = axios.get(APIs.GET_PRIVACY_POLICY);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_PRIVACY_POLICY,
				payload: response
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to get privacy policy document"}</div>);
		});
	}
};
export const GetAboutUs = () => {
	const request = axios.get(APIs.GET_ABOUT_US);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_ABOUT_US,
				payload: response
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to get about us document"}</div>);
		});
	}
};