import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteFeedback, getFeedback, postFeedback} from "../../../redux/commonStoreAndActions/feedback-actions";
import Draft from '../../../ui-common/draft';
import DraftView from '../../../ui-common/draft/draftView';

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
	link: {
		color: "#0000ff",
		textDecoration: "underline",
		cursor: "pointer"
	},
	comments: {
		height: "calc(50vh - 50px)",
		overflowY: "auto",
		padding: "1rem"
	},
	comment: {
		backgroundColor: "#eeeeee",
		color: "#000",
		borderRadius: "4px",
		margin: "2rem 0",
		padding: "2rem",
		"&>div:first-child": {
			margin: "0 0 1rem",
			fontStyle: "italic",
			fontSize: "0.75rem"
		}
	},
	divider: {
		border: "rgba(0,0,0,0.1) 1px solid",
		margin: "20px 0",
		height: 0
	},
	editor: {
		height: "calc(25vh - 50px)",
		width: "80%",
		margin: "0 auto"
	},
	feedbackNotAvailable: {
		textTransform: "uppercase",
		textAlign: "center",
		display: "flex",
	}
});

const formatter = new Intl.DateTimeFormat('en-us', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	hour12: true
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class FeedbackDialog extends Component {
	componentDidUpdate = (prevProps, prevState) => {
		let {_id} = this.props.rowData;
		if (this.state.dialogOpen && this.state.dialogOpen !== prevState.dialogOpen) {
			this.props.getFeedback(_id);
		}
	};
	handleOpenClose = () => {
		this.setState({
			dialogOpen: !this.state.dialogOpen
		});
	};

	save = (feedback, cb) => {
		let {_id} = this.props.rowData;
		this.props.postFeedback(_id, feedback, () => {
			setTimeout(() => {
				this.props.getFeedback(_id);
			}, 0);
			cb();
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false
		};
	}

	render() {
		let {classes, rowData} = this.props;
		return this.state.dialogOpen ? (
			<Dialog fullScreen open={true} onClose={this.handleOpenClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton color="inherit" onClick={this.handleOpenClose} aria-label="Close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" color="inherit" className={classes.flex}>
							{"Feedback : " + rowData.firstName + " " + rowData.lastName}
						</Typography>
					</Toolbar>
				</AppBar>
				{this.props.feedback.list.length === 0 ? (
					<div className={classes.comments}>
						<div className={classes.feedbackNotAvailable}>Feedback not available</div>
					</div>
				) : (
					<div className={classes.comments}>
						{this.props.feedback.list.map((comment) => {
							return (
								<div className={classes.comment}>
									<div>{formatter.format(new Date(comment.createdOn))}</div>
									<div>
										<DraftView comment={comment.comment}/>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<div className={classes.divider}/>
				<div className={classes.editor}>
					<Draft save={this.save}/>
				</div>
			</Dialog>
		) : (
			<div onClick={this.handleOpenClose} className={classes.link}>Feedback</div>
		);
	}
}

const mapStateToProps = state => ({
	feedback: state.feedback || {}
});

export default connect(mapStateToProps, {
	getFeedback, postFeedback, deleteFeedback,
})(withStyles(styles)(FeedbackDialog));