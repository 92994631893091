import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, {Component} from 'react';

class AlertDialog extends Component {
	handleClose = () => {
		let {closeDialog} = this.props;
		if (closeDialog) {
			closeDialog();
		}
	};
	onEndClientNameChanged = (e) => {
		let value = e.target.value;
		this.setState({
			endClientName: value
		})
	};
	onEndClientStateChanged = (e) => {
		let value = e.target.value;
		this.setState({
			endClientState: value
		})
	};
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state.endClientName, this.state.endClientState);
	};

	constructor(props) {
		super(props);
		this.state = {
			open: true,
			endClientName: "",
			endClientState: ""
		};
	}

	render() {
		let {endClientName, endClientState} = this.state;
		return (
			<div>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Add New End Client"}</DialogTitle>
					<form>
						<DialogContent>
							<TextField
								id="new-end-client-name"
								label="Client Name"
								required
								style={{width: "100%"}}
								value={endClientName}
								onChange={this.onEndClientNameChanged}
								margin="normal"
							/>
							<TextField
								id="new-end-client-state"
								label="State"
								style={{width: "100%"}}
								value={endClientState}
								onChange={this.onEndClientStateChanged}
								margin="normal"
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Cancel
							</Button>
							<Button disabled={!(endClientName && endClientName.length)} onClick={this.handleSubmit}
							        color="primary" autoFocus>
								Submit
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	}

}

export default AlertDialog;
