import {TYPES} from "./user-actions";

export default (state = {
	userInfo: {},
	isLoggedIn: window.collabriumSystems && window.collabriumSystems.isUserAuthenticated === "a",
	loginErr: null
}, action = {}) => {
	switch (action.type) {
		case TYPES.ON_LOGIN:
			return {...state, isLoggedIn: action.payload.data.status, loginErr: action.payload.data.message};
		case TYPES.ON_LOGOUT:
			return {...state, isLoggedIn: false, loginErr: null};
		default:
			return state;
	}
}