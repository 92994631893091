import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/core/styles/index";
import React, {Component} from "react";
import {getCategoryName} from "../job-post/jobMenuOptions";
import RemoveEmail from "./removeEmail";

const styles = theme => ({
	rowContainer: {
		width: '100%',
		display: 'block', // Fix IE 11 issue.
		flexGrow: 1
	},
	dataRow: {
		margin: "10px 0 10px 0"
	},
	fieldTitle: {
		fontWeight: 700,
		margin: "0 0 5px"
	},
	removeContainer: {
		padding: "10px 0 0"
	}
});

class MobileView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let {classes, removeFromMailingList} = this.props,
			{name, email, category = ""} = this.props.rowData;
		return (
			<div className={classes.rowContainer}>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Name:</div>
					<div>{name}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Email Id:</div>
					<div>{email}</div>
				</div>
				<div className={classes.dataRow}>
					<div className={classes.fieldTitle}>Category:</div>
					<div>{getCategoryName(category)}</div>
				</div>
				<Divider/>
				<div className={classes.removeContainer}>
					<RemoveEmail {...this.props} removeFromMailingList={removeFromMailingList}/>
				</div>
			</div>
		);
	}
}

export default (withStyles(styles)(MobileView));