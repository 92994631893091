import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {GetJobDetails} from "../../../redux/commonStoreAndActions/job-actions";
import {getCandidateAvailability, getWorkAuthorization} from "../../non-auth-pages/job-view-public/resumeUploadOptions";
import JobDetailView from "../job-details";

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
	iFrameContainer: {
		widths: "100%",
		height: "calc(100vh - 64px)"
	},
	link: {
		color: "#0000ff",
		textDecoration: "underline",
		cursor: "pointer"
	},
	candidateDetails: {
		backgroundColor: "#fff",
		padding: "20px"
	},
	row: {
		marginBottom: "20px",
		display: "flex",
		alignItems: "center"
	},
	field: {
		fontWeight: "bold",
		margin: "0 20px"
	},
	jobPosition: {
		color: "#0000ff",
		fontWeight: "bold",
		"&:hover": {
			"textDecoration": "underline",
			cursor: "pointer"
		}
	}
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends Component {
	handleOpenClose = () => {
		this.setState({
			dialogOpen: !this.state.dialogOpen
		});
	};

	componentDidUpdate = (prevProps, prevState) => {
		let {jobId} = this.props.rowData;
		if (this.state.dialogOpen && this.state.dialogOpen !== prevState.dialogOpen && jobId) {
			this.props.GetJobDetails(jobId, this.onJobDetailsFetched);
		}
	};

	onJobPositionClicked = () => {
		if (this.props.rowData.jobId) {
			this.setState({
				isJobDetailsVisible: true
			});
		}
	};
	closeJobDetails = () => {
		this.setState({
			isJobDetailsVisible: false
		});
	};
	onJobDetailsFetched = (data) => {
		this.setState({
			jobDetails: data,
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			jobDetails: null,
			isJobDetailsVisible: false
		};
	}

	render() {
		let {classes, rowData} = this.props;
		let {jobDetails, isJobDetailsVisible} = this.state;
		return this.state.dialogOpen ? (
			<Dialog fullScreen open={true} onClose={this.handleOpenClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton color="inherit" onClick={this.handleOpenClose} aria-label="Close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" color="inherit" className={classes.flex}>
							{rowData.firstName + " " + rowData.lastName}
						</Typography>
					</Toolbar>
				</AppBar>
				{isJobDetailsVisible ? (
					<JobDetailView data={jobDetails} onBack={this.closeJobDetails}/>
				) : (
					<Fragment>
						<div className={classes.candidateDetails}>
							<Grid container className={classes.root} xs={12} sm={12} justify="center" spacing={32}>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Name: </span>
									<span>{rowData.firstName + " " + rowData.lastName}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Email Id: </span>
									<span>{rowData.emailId}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Supplier Name: </span>
									<span>{rowData.supplierName}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Contact Number: </span>
									<span>{rowData.phone}</span>
								</Grid>


								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Contact Number: </span>
									<span>{rowData.phone}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Experience (in years): </span>
									<span>{rowData.yearsOfExperience}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Current Location: </span>
									<span>{rowData.currentLocation}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Expected Salary: </span>
									<span>{rowData.expectedSalary}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Work Authorization: </span>
									<span>{getWorkAuthorization(rowData.workAuthorization)}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>C2C Rate: </span>
									<span>{rowData.c2cRate}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Availability: </span>
									<span>{getCandidateAvailability(rowData.availability)}</span>
								</Grid>
								<Grid item xs={12} sm={6}>
									<span className={classes.field}>Job Position: </span>
									<span className={classes.jobPosition}
									      onClick={this.onJobPositionClicked}>{jobDetails ? jobDetails.jobPosition : "-"}</span>
								</Grid>
							</Grid>
						</div>
						<div className={classes.iFrameContainer}>
							<object type="application/pdf"
							        data={"/dashboard/v1/api/candidate/view-resume/" + rowData.resume}
							        style={{width: "100%", height: "100%", border: "none"}}>
							</object>
						</div>
					</Fragment>
				)}
			</Dialog>
		) : (
			<div onClick={this.handleOpenClose} className={classes.link}>Resume</div>
		)
	}
}

const mapStateToProps = state => ({
	job: state.job
});

export default connect(mapStateToProps, {
	GetJobDetails,
})(withStyles(styles)(FullScreenDialog));
