import Button from "@material-ui/core/Button";
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from "react";
import {Link} from 'react-router-dom'
import Slider from "react-slick";

const styles = theme => (
	{
		sliderContainer: {
			position: "relative",
			overflow: "hidden"
		},
		messageContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			position: "absolute",
			top: "calc(50vh - 80px)",
			width: "100%",
			textAlign: "center",
			color: "#fff",
			[theme.breakpoints.down('sm')]: {
				top: "4rem",
				fontSize: "1rem"
			}
		},
		imgContainer1: {
			backgroundColor: "#000",
		},
		img: {
			margin: "0 auto",
			height: "calc(100vh - 64px)",
		},
		imgWidth: {
			width: "100%"
		},
		img1: {
			height: "calc(100vh - 64px)",
			maxHeight: "840px",
			backgroundImage: "url('/images/staffing_ash.png')",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover"
		},
		img2: {
			height: "calc(100vh - 64px)",
			maxHeight: "840px",
			backgroundImage: "url('/images/it_delivery_ash.png')",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover"
		},
		img3: {
			height: "calc(100vh - 64px)",
			maxHeight: "840px",
			backgroundImage: "url('/images/it_maintenance_ash.png')",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover"
		},
		message1: {
			fontSize: "55px",
			margin: "0 20px 20px",
			[theme.breakpoints.up('xs')]: {
				fontSize: "1rem",
				margin: "0",
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: "55px",
			},
		},
		message2: {
			fontSize: "12px",
			[theme.breakpoints.up('xs')]: {
				margin: "15px 0 0",
				fontSize: "12px",
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: "18px",
			},
		},
		button: {
			cursor: "pointer",
			borderRadius: "4px",
			backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
			padding: "10px 40px",
			color: "#fff",
			margin: "40px 0 0",
			[theme.breakpoints.up('xs')]: {
				margin: "20px 0 0",
			},
		}
	}
);

class HomeSlider extends Component {
	render() {
		let {classes} = this.props;
		return (
			<div className={classes.sliderContainer}>
					<div>
						<div className={classes.imgContainer}>
							<img src={"/images/it_delivery_ash.png"} className={classes.imgWidth}/>
						</div>
						<div className={classes.messageContainer}>
							<div className={classes.message1}>Technology is our DNA</div>
							<div className={classes.message2}>
								We take the “long view” to provide smart, strategic, cost-effective value to our clients and our employees
							</div>
							<div onClick={(e)=> {e.stopPropagation();}}>
								<Link to={"services/it-solution-delivery"}>
									<div className={classes.button}>Know More</div>
								</Link>
							</div>
						</div>
					</div>
			</div>
		);
	}
}

export default withStyles(styles)(HomeSlider);
