import {combineReducers} from 'redux';
import candidate from './commonStoreAndActions/candidate-reducer';
import feedback from './commonStoreAndActions/feedback-reducer';
import job from './commonStoreAndActions/job-reducer'
import mailList from "./commonStoreAndActions/mail-reducer"
import service from './commonStoreAndActions/service-reducer';
import stats from "./commonStoreAndActions/stats-reducer";
import signInUser from './commonStoreAndActions/user-reducer';
import documents from './commonStoreAndActions/document-reducer';

export default combineReducers({
	signInUser: signInUser,
	job: job,
	stats: stats,
	mailList: mailList,
	candidate: candidate,
	service: service,
	feedback: feedback,
	documents: documents
});