import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from "react-redux";
import {
	ChangeJobStatus,
	GetClientList,
	GetEndClientList,
	GetJobList
} from "../../../redux/commonStoreAndActions/job-actions";
import Table from "../../../ui-common/table";
import {isMobile} from "../../../utils/detectBrowser";
import {config} from "./tableConfig";

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	tableContainer: {
		height: "calc(100vh - 160px)"
	}
});

class JobView extends Component {
	componentDidMount = () => {
		let {GetClientList, GetEndClientList} = this.props;
		GetClientList();
		GetEndClientList();
	};
	getTableData = (data, callback) => {
		data.status = this.props.status;
		this.props.GetJobList(data, callback);
	};
	onJobStatusChange = (data) => {
		this.props.ChangeJobStatus(data, () => {
			this.table.resetTable();
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			isClientAndEndClientFetched: false
		};
	}

	render() {
		let {classes, job} = this.props,
			{clientList, endClientList} = job;
		return (
			<div className={classes.tableContainer}>
				<Typography variant="h4" gutterBottom component="h4">
					{"Job List - " + this.props.status}
				</Typography>
				{clientList && endClientList && clientList.length && endClientList.length ? (
					<Table
						ref={(ref) => {
							this.table = ref;
						}}
						rowHeight={isMobile.any() ? 300 : 56}
						headerHeight={isMobile.any() ? 0 : 56}
						getTableData={this.getTableData}
						config={config(clientList, endClientList, this.props.status, this.onJobStatusChange)}/>
				) : null}
			</div>
		);
	}
}

JobView.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	job: state.job
});

export default connect(mapStateToProps, {
	GetJobList,
	GetClientList,
	GetEndClientList,
	ChangeJobStatus
})(withStyles(styles)(JobView));