import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from "@material-ui/core/styles/index";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Logout from "@material-ui/icons/SettingsPower";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {LogoutUser} from "../../redux/commonStoreAndActions/user-actions";
import {APP_URLS} from "../appUrl";
import CandidateView from "./candidate-view";
import DashboardHome from "./dashboard-home";
import JobView from "./dashboard-job-view";
import JobPost from "./job-post";
import MailingList from "./mailing-list";
import MenuItems from "./menuItems";

const drawerWidth = 240;
const styles = (theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		backgroundColor: "#4a4c4b",
		boxShadow: "0 8px 26px 0 rgba(167, 167, 167, 0.5)",
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 7 + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		margin: "0 10px 0 0"
	},
}));

class Dashboard extends Component {
	handleDrawerToggle = () => {
		this.setState({
			open: !this.state.open
		});
	};
	logOut = () => {
		this.props.LogoutUser();
	};

	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}

	getContent() {
		let {pageKey, urlJobStatus, jobId, applicationStatus} = this.props;
		switch (pageKey) {
			case APP_URLS.NEW_JOB_POST.key: {
				return (
					<JobPost/>
				);
			}
			case APP_URLS.EDIT_JOB_POST.key: {
				return (
					<JobPost jobId={jobId}/>
				);
			}
			case APP_URLS.DASHBOARD_HOME.key: {
				return (
					<DashboardHome/>
				);
			}
			case APP_URLS.VIEW_JOBS.key: {
				return (
					<JobView status={urlJobStatus}/>
				)
			}
			case APP_URLS.CANDIDATE.key: {
				return (
					<CandidateView status={applicationStatus}/>
				);
			}
			case APP_URLS.MAILING_LIST.key: {
				return (
					<MailingList/>
				)
			}
		}
	}

	render() {
		const {classes, theme, signInUser} = this.props;
		const {open} = this.state;
		return (
			<div className={classes.root}>
				{!signInUser.isLoggedIn ? (
					<Redirect to={APP_URLS.HOME.url}/>
				) : null}
				<CssBaseline/>
				<AppBar position="fixed"
				        className={classNames(classes.appBar, {
					        [classes.appBarShift]: open,
				        })}>
					<Toolbar disableGutters={!open}>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerToggle}
							className={classNames(classes.menuButton, {
								[classes.hide]: open,
							})}>
							<MenuIcon/>
						</IconButton>
						<Typography variant="h6" color="inherit" noWrap>
							Collabrium Systems
						</Typography>
						<div className={classes.grow}/>
						<div className={classes.sectionDesktop}>
							<IconButton color="inherit" onClick={this.logOut}>
								<Logout/>
							</IconButton>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={classNames(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: classNames({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
					open={open}>
					<div className={classes.toolbar}>
						<IconButton onClick={this.handleDrawerToggle}>
							{theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
						</IconButton>
					</div>
					<Divider/>
					<MenuItems/>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.toolbar}/>
					{this.getContent()}
				</main>
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	signInUser: state.signInUser
});

export default connect(mapStateToProps, {LogoutUser})(withStyles(styles, {withTheme: true})(Dashboard));