import React, {Component} from "react";
import {connect} from 'react-redux';
import {GetTermsOfUse} from "../../../redux/commonStoreAndActions/documen-action";

class TermsOfUse extends Component {
	componentDidMount() {
		let html = document.documentElement;
		html.scrollTop = 0;
		if(!this.props.termsOfUse) {
			this.props.GetTermsOfUse();
		}
	}

	render() {
		return (
			<div style={{padding: "20px"}} dangerouslySetInnerHTML={{__html: this.props.termsOfUse}}/>
		);
	}
}

const mapStateToProps = state => ({
	termsOfUse: state.documents.termOfUse
});
export default connect(mapStateToProps, {GetTermsOfUse})(TermsOfUse);