import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {APIs} from "../../api/index";

export const TYPES = {
	GET_CANDIDATE_STAT: "GET_CANDIDATE_STAT_1"
};

export const GetCandidateStatsCount = (status) => {
	const request = axios.get(APIs.JOB_CANDIDATE_COUNT + status);
	return (dispatch) => {
		request.then((response) => {
			dispatch({
				type: TYPES.GET_CANDIDATE_STAT,
				payload: response
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to get candidate stats"}</div>);
		});
	}
};

export const GetCandidateList = (params, callback) => {
	const request = axios.get(APIs.CANDIDATE_LIST + params.status + "/" + params.skip + "/" + params.limit + "/" + params.searchText);
	return (dispatch) => {
		request.then((response) => {
			let pageNo = params.pageNo;
			callback({...response.data, pageNo});
		}).catch((err) => {
			toast.error(<div>{"Unable to get candidate list"}</div>);
		});
	}
};

export const ChangeResumeStatus = (data, callback) => {
	const request = axios.post(APIs.CANDIDATE_STATUS, data);
	return (dispatch) => {
		request.then((data) => {
			callback();
			toast.info(<div>{"Successfully updated status of the resume"}</div>);
		}).catch((err) => {
			toast.error(<div>{"Unable to change the status of the resume"}</div>);
		});
	}
};