import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

function AlertDialog(props) {
	let {closeDialog, removeMailFromMailingList, email} = props;
	return (
		<Dialog
			open={true}
			onClose={closeDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{"Do you really want to remove '" + email + "' from mailing list"}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary">
					Cancel
				</Button>
				<Button onClick={removeMailFromMailingList} color="primary" autoFocus>
					Remove
				</Button>
			</DialogActions>
		</Dialog>

	);
}

export default AlertDialog;
