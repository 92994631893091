
export const APP_URLS = {
	HOME: {url: "/", key: "HOME"},
	CAREER: {url: "/career", key: "CAREER"},
	SERVICES: {url: "/services", key: "SERVICES"},
	CONTACT_US: {url: "/contact-us", key: "CONTACT_US"},
	ABOUT_US: {url: "/about-us", key: "ABOUT_US"},
	JOB_VIEW_PUBLIC: {url: "/job-view", key: "JOB_VIEW_PUBLIC"},
	SIGN_IN: {url: "/sign-in", key: "SIGN_IN"},
	NEW_JOB_POST: {url: "/dashboard/new-job-post", key: "NEW_JOB_POST"},
	MAILING_LIST: {url: "/dashboard/mailing-list", key: "MAILING_LIST"},
	EDIT_JOB_POST: {url: "/dashboard/edit-job-post", key: "EDIT_JOB_POST"},
	DASHBOARD_HOME: {url: "/dashboard", key: "DASHBOARD_HOME"},
	VIEW_JOBS: {url: "/dashboard/job/views", key: "VIEW_JOBS"},
	CANDIDATE: {url: "/dashboard/candidate-view", key: "CANDIDATE"},
	TermsOfUse: {url: "/terms-of-use", key: "TermsOfUse"},
	PRIVACY_POLICY: {url: "/privacy-policy", key: "PRIVACY_POLICY"},
};