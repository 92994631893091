import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router";
import validator from "validator";
import {SignInUser} from "../../../redux/commonStoreAndActions/user-actions";
import {APP_URLS} from "../../appUrl";

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: "#fe52a3",
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
		backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
		color: "#fff",
		"&:disabled": {
			backgroundImage: "linear-gradient(to right, #a1a1a1, #a1a1a1)",
			color: "#fff"
		}
	},
	err: {
		textTransform: "capitalize",
		color: "#c71111"
	}
});

const LOCAL_STORE_KEYS = {
	EMAIL_ID: "email_id",
	REMEMBER_ME: "remember_me"
};

const saveInLocalStorage = (key, value) => {
	localStorage.setItem(key, value);
};

const getFromLocalStorage = (key) => {
	return localStorage.getItem(key);
};

const deleteFromLocalStore = (key) => {
	localStorage.removeItem(key);
};

class SignIn extends Component {
	onEmailChange = (e) => {
		let value = e.target.value;
		this.setState({
			emailId: value
		});
	};
	onPasswordChange = (e) => {
		let value = e.target.value;
		this.setState({
			password: value
		});
	};
	onRememberMeChange = (e, isChecked) => {
		this.setState({
			rememberMe: isChecked
		});
	};
	onSignInClicked = (e) => {
		let {emailId, password, rememberMe} = this.state;
		if (!this.validate()) {
			return;
		}
		if (rememberMe) {
			saveInLocalStorage(LOCAL_STORE_KEYS.EMAIL_ID, emailId);
			saveInLocalStorage(LOCAL_STORE_KEYS.REMEMBER_ME, rememberMe);
		}
		else {
			deleteFromLocalStore(LOCAL_STORE_KEYS.EMAIL_ID);
			deleteFromLocalStore(LOCAL_STORE_KEYS.REMEMBER_ME);
		}
		this.props.SignInUser(emailId, password);
	};

	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			emailId: "",
			password: "",
			rememberMe: false
		};
	}

	validate() {
		let {emailId, password} = this.state;
		return emailId && validator.isEmail(emailId) && password && password.length > 5;
	}

	render() {
		const {emailId, password, rememberMe,} = this.state;
		const {classes, signInUser} = this.props;
		return (
			<main className={classes.main}>
				{signInUser.isLoggedIn ? (
					<Redirect to={APP_URLS.DASHBOARD_HOME.url}/>
				) : null}
				<CssBaseline/>
				<Paper className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockIcon/>
					</Avatar>
					<Typography component="h1" variant="h5">
						{signInUser.loginErr ? (
							<div className={classes.err}>{signInUser.loginErr}</div>
						) : "Sign in"}
					</Typography>
					<form className={classes.form}>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="email">Email Address</InputLabel>
							<Input id="email" name="email" autoComplete="email" autoFocus onChange={this.onEmailChange}
							       value={emailId}/>
						</FormControl>
						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="password">Password</InputLabel>
							<Input name="password" type="password" id="password" autoComplete="current-password"
							       onChange={this.onPasswordChange} value={password}/>
						</FormControl>
						<FormControlLabel
							control={<Checkbox value="remember" color="primary"/>}
							label="Remember me"
							checked={rememberMe}
							onChange={this.onRememberMeChange}/>
						<Button
							type="button"
							fullWidth
							disabled={!this.validate()}
							variant="contained"
							onClick={this.onSignInClicked}
							className={classes.submit}>
							Sign in
						</Button>
					</form>
				</Paper>
			</main>
		);
	}
}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
	signInUser: state.signInUser
});
export default connect(mapStateToProps, {SignInUser})(withStyles(styles)(SignIn));
