import React, {Component} from "react";
import {connect} from 'react-redux';
import {GetAboutUs} from "../../../redux/commonStoreAndActions/documen-action";

class AboutUs extends Component {
	componentDidMount() {
		let html = document.documentElement;
		html.scrollTop = 0;
		if(!this.props.aboutUs) {
			this.props.GetAboutUs();
		}
	}

	render() {
		return (
			<div style={{padding: "20px"}} dangerouslySetInnerHTML={{__html: this.props.aboutUs}}/>
		);
	}
}

const mapStateToProps = state => ({
	aboutUs: state.documents.aboutUs
});
export default connect(mapStateToProps, {GetAboutUs})(AboutUs);