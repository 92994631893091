import {TYPES} from "./feedback-actions";

export default (state = {
	list: [],
}, action = {}) => {
	switch (action.type) {
		case TYPES.GET_FEEDBACK:
			return {...state, list: action.payload.data};
		default:
			return state;
	}
}