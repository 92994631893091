import React, {Component} from "react";
import {connect} from 'react-redux';
import {GetPrivacyPolicy} from "../../../redux/commonStoreAndActions/documen-action";

class PrivacyPolicy extends Component {
	componentDidMount() {
		let html = document.documentElement;
		html.scrollTop = 0;
		if(!this.props.privacyPolicy) {
			this.props.GetPrivacyPolicy();
		}
	}

	render() {
		return (
			<div style={{padding: "20px"}} dangerouslySetInnerHTML={{__html: this.props.privacyPolicy}}/>
		);
	}
}

const mapStateToProps = state => ({
	privacyPolicy: state.documents.privacyPolicy
});
export default connect(mapStateToProps, {GetPrivacyPolicy})(PrivacyPolicy);
