import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, {Component} from "react";
import {toast} from "react-toastify";
import {PostPublicResume} from "../../../redux/commonStoreAndActions/job-actions";
import ResumeUpload from "../job-view-public/uploadResume";
import JobList from "./jobList";

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		[theme.breakpoints.up(401 + theme.spacing.unit * 3 * 2)]: {
			width: "80%",
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	pageTitle: {
		margin: "20px 0",
		fontSize: "40px",
		lineHeight: 1.48,
		letterSpacing: "0.7px",
		color: "#2c2c2c"
	},
	pageMessage: {
		textTransform: "uppercase",
		width: "100%"
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	button: {
		margin: "15px 0 0",
		padding: "15px 30px",
		borderRadius: "4px",
		backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
		color: "#fff",
		"&:disabled": {
			backgroundImage: "linear-gradient(to right, #a1a1a1, #a1a1a1)",
			color: "#c2c2c2",
		}
	},
	careerImgContainer: {
		position: "relative",
		cursor: "pointer",
	},
	careerImg: {
		width: "100%"
	},
	uploadMessage: {
		fontSize: "24px",
		color: "#fff",
		position: "absolute",
		top: "30px",
		left: "30px",
	},
	uploadActionMessage: {
		color: "#fff",
		position: "absolute",
		right: "30px",
		bottom: "30px",
		"&>div": {
			width: "160px",
			display: "grid",
			gridTemplateColumns: "auto 20px",
			alignItems: "center"
		},
	},
	oval: {
		width: "18px",
		height: "18px",
		backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
		content: " ",
		borderRadius: "50%"
	},
	allJobMessage: {
		fontSize: "24px",
		color: "#fff",
		position: "absolute",
		bottom: "30px",
		left: "30px",
	},
	allJobActionMessage: {
		color: "#fff",
		position: "absolute",
		right: "30px",
		top: "30px",
		"&>div": {
			width: "120px",
			display: "grid",
			gridTemplateColumns: "auto 20px",
			alignItems: "center"
		},
	},
	jobListMessage: {
		lineHeight: "1.48",
		letterSpacing: "0.7px",
		color: "#2c2c2c",
		fontSize: "26px"
	},
	clear: {
		margin: "0 !important",
	}
});

class Career extends Component {
	onFromSubmit = (e) => {
		e.preventDefault();
		if (this.validate()) {
			let {skill, location} = this.state;
			if (skill && skill.length) {

			}
		}
	};
	validate = () => {
		let {skill, location} = this.state;
		return (skill && skill.length) || (location && location.length);
	};
	onSkillChange = (e) => {
		this.setState({
			skill: e.target.value
		})
	};
	onLocationChange = (e) => {
		this.setState({
			location: e.target.value
		})
	};
	showAllJobs = (e) => {
		this.setState({
			location: "",
			skill: ""
		});
	};

	closeResumeUploadDialog = () => {
		this.setState({
			showResumeUpload: false
		});
	};
	uploadResumeClicked = () => {
		this.setState({
			showResumeUpload: true
		});
	};
	uploadResume = (data) => {
		PostPublicResume(data, () => {
			this.closeResumeUploadDialog();
			if (this.state.jobId) {
				toast.info(<div>{"Successfully applied for the job"}</div>);
			}
			else {
				toast.info(<div>{"Successfully uploaded the resume"}</div>);
			}
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			skill: "",
			location: "",
			showResumeUpload: false
		};
	}

	render() {
		let {skill, location, showResumeUpload} = this.state;
		const {classes} = this.props;
		return (
			<main className={classes.main}>
				{showResumeUpload ? (
					<ResumeUpload closeDialog={this.closeResumeUploadDialog}
					              onSubmit={this.uploadResume}
					              jobId={null}
					              jobPosition={null}/>
				) : null}
				<CssBaseline/>
				<Grid container xs={12} spacing={40} className={classes.clear}>
					<Grid item xs={12} className={classes.pageTitle}>
						We will find the best job for you!
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.careerImgContainer} onClick={this.uploadResumeClicked}>
							<img src={"/images/career_submit_job.png"} className={classes.careerImg}/>
							<div className={classes.uploadMessage}>Get included in our list</div>
							<div className={classes.uploadActionMessage}>
								<div>
									<div>Upload Resume</div>
									<div className={classes.oval}/>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.careerImgContainer} onClick={this.showAllJobs}>
							<img src={"/images/career_all_job.png"} className={classes.careerImg}/>
							<div className={classes.allJobMessage}>Browse all open positions</div>
							<div className={classes.allJobActionMessage}>
								<div>
									<div>Show All</div>
									<div className={classes.oval}/>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<form onSubmit={this.onFromSubmit} className={classes.form}>
							<Grid container xs={12} justify="flex-end"
							      alignItems="center" spacing={16}>
								<Grid item xs={12} sm={6}>
									<FormControl margin="normal" fullWidth>
										<TextField id="skill"
										           name="skill"
										           label="Skill"
										           autoComplete="skill"
										           autoFocus
										           onChange={this.onSkillChange}
										           margin="normal"
										           variant="outlined"
										           value={skill}/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl margin="normal" fullWidth>
										<TextField id="location"
										           name="location"
										           label="Location"
										           type="text"
										           autoComplete="location"
										           onChange={this.onLocationChange}
										           margin="normal"
										           variant="outlined"
										           value={location}/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Button type={"submit"} disabled={!this.validate()} variant="contained"
									        color="primary"
									        className={classes.button}>
										Search Job
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					<Grid item xs={12} className={classes.jobListMessage}>Latest Job Posts</Grid>
					<JobList skill={skill} location={location}/>
				</Grid>
			</main>
		);
	}
}

export default withStyles(styles)(Career);
