import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {APIs} from "../../api/index";

export const TYPES = {
	GET_CLIENT_LIST: "GET_CLIENT_LIST_9",
	GET_END_CLIENT_LIST: "GET_END_CLIENT_LIST_10",
	POST_NEW_JOB: "POST_NEW_JOB_11",
	JOB_SEARCH: "JOB_SEARCH_12",
	GET_JOB_BY: "GET_JOB_BY_13",
	GET_DETAILS_OF_PUBLIC_JOB: "GET_DETAILS_OF_PUBLIC_JOB_14",
	SEARCH_PUBLIC_JOB_BY_SKILL: "SEARCH_PUBLIC_JOB_BY_SKILL_15"
};

export const GetClientList = (cb) => {
	const request = axios.get(APIs.CLIENT_LIST);
	return (dispatch) => {
		request.then((response) => {
			if (cb) {
				cb(response.data);
			}
			dispatch({
				type: TYPES.GET_CLIENT_LIST,
				payload: response
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const GetEndClientList = (cb) => {
	const request = axios.get(APIs.END_CLIENT_LIST);
	return (dispatch) => {
		request.then((response) => {
			if (cb) {
				cb(response.data);
			}
			dispatch({
				type: TYPES.GET_END_CLIENT_LIST,
				payload: response
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const SaveNewClientName = (client, cb) => {
	const request = axios.post(APIs.CLIENT_LIST, client);
	return () => {
		request.then((data) => {
			cb(data);
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const SaveNewEndClientName = (endClient, cb) => {
	const request = axios.post(APIs.END_CLIENT_LIST, endClient);
	return () => {
		request.then((data) => {
			cb(data);
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const PostNewJob = (job) => {
	const request = axios.post(APIs.JOB, job);
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.POST_NEW_JOB,
				payload: data
			});
			toast.info(<div>{"Successfully posted job"}</div>);
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const GetJobList = (params, callback) => {
	const request = axios.get(APIs.JOB_LIST + "/" + params.status + "/" + params.skip + "/" + params.limit);
	return (dispatch) => {
		request.then((response) => {
			let pageNo = params.pageNo;
			callback({...response.data, pageNo});
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const GetJobDetails = (jobId, callback) => {
	const request = axios.get(APIs.JOB + "/" + jobId);
	return (dispatch) => {
		request.then((response) => {
			callback(response.data);
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const ChangeJobStatus = (data, callback) => {
	const request = axios.post(APIs.JOB_STATUS, data);
	return (dispatch) => {
		request.then((data) => {
			callback();
			toast.info(<div>{"Successfully updated status of the job"}</div>);
		}).catch((err) => {
			toast.error(<div>{"Unable to change the status of the job"}</div>);
		});
	}
};
export const GetJobBy = (by, callback) => {
	const request = axios.get(APIs.GET_JOB_BY + "/" + by);
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.GET_JOB_BY,
				payload: data
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to fetch job by " + by}</div>);
		});
	}
};

export const GetPublicJobList = (callback) => {
	const request = axios.get(APIs.GET_PUBLIC_JOB_LIST);
	return (dispatch) => {
		request.then((response) => {
			callback(response.data);
		}).catch((err) => {
			toast.error(<div>{"Unable to fetch job list "}</div>);
		});
	}
};

export const PostPublicResume = (data, callback) => {
	const request = axios.post(APIs.POST_PUBLIC_RESUME, data);
	request.then((response) => {
		callback(response.data);
	}).catch((err) => {
		toast.error(<div>{"Unable to submit form data. "}</div>);
	});
};

export const UploadResume = (data, callback) => {
	const request = axios.post(APIs.POST_PUBLIC_RESUME_FILE, data);
	request.then((response) => {
		callback(response.data);
	}).catch((err) => {
		toast.error(<div>{"Unable to upload resume. "}</div>);
	});
};

export const GetJobDetailsPublic = (jobId) => {
	const request = axios.get(APIs.GET_JOB_DETAILS_PUBLIC + "/" + jobId);
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.GET_DETAILS_OF_PUBLIC_JOB,
				payload: data
			});
		}).catch((err) => {
			toast.error(<div>{"Unable to fetch job by " + by}</div>);
		});
	}
};

export const SearchJobBySkill = (callback, skill, location) => {
	const request = axios.get(APIs.GET_PUBLIC_JOB_BY_SKILL + "/" + encodeURIComponent(skill) + "/" + encodeURIComponent(location));
	return (dispatch) => {
		request.then((response) => {
			callback(response.data);
		}).catch((err) => {
			toast.error(<div>{"Unable to fetch job list "}</div>);
		});
	}
};
