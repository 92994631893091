import {TYPES} from "./job-actions";

export default (state = {
	clientList: [],
	endClientList: [],
	publicJobDetails: {}
}, action = {}) => {
	switch (action.type) {
		case TYPES.GET_CLIENT_LIST:
			return {...state, clientList: action.payload.data};
		case TYPES.GET_END_CLIENT_LIST:
			return {...state, endClientList: action.payload.data};
		case TYPES.GET_DETAILS_OF_PUBLIC_JOB:
			let {data} = action.payload;
			return {...state, publicJobDetails: data && data.length && data[0]};
		default:
			return state;
	}
}