import {applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";

const customMiddleWare = store => next => action => {
	console.log("Middleware triggered:", action);
	next(action);
};
/* eslint-disable no-underscore-dangle */
const middleware = [thunk, customMiddleWare],
	composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
/* eslint-enable */

export const ReduxMiddleware = composeEnhancers(applyMiddleware(...middleware));