import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {GetPublicJobList, SearchJobBySkill} from "../../../redux/commonStoreAndActions/job-actions";

let moment = require('moment');
const styles = theme => ({
	jobContainer: {
		borderRadius: "5px",
		backgroundColor: "#ffffff",
		color: "#454545",
		padding: "20px",
		border: "1px solid #f0f0f1",
		position: "relative",
		"&>div": {
			display: "grid",
			gridTemplateColumns: "80px auto",
			alignItems: "center"
		}
	},
	jobIcon: {
		background: "linear-gradient(to right, #fe985b, #fe4fa7)",
		height: "50px",
		width: "50px",
		borderRadius: "50%"
	},
	jobTitle: {
		fontSize: "18px",
		margin: "10px 0 10px",
		fontWeight: "500",
		textTransform: "uppercase"
	},
	jobLocation: {
		fontSize: "14px"
	},
	jobCreatedOn: {
		textAlign: "right",
		fontSize: "0.75rem",
		marginTop: "1rem"
	},
	jobPostText: {
		marginRight: "10px",
		fontWeight: "500",
		textTransform: "uppercase"
	},
	jobApply: {
		position: "absolute",
		top: "5px",
		right: "20px",
		fontSize: "10px",
		color: "#0000ff",
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline"
		}
	}
});

class JobList extends Component {
	componentDidMount = () => {
		this.props.GetPublicJobList(this.onJobListFetched);
	};
	componentDidUpdate = (prevProps) => {
		const {
			skill,
			location,
			SearchJobBySkill
		} = this.props;
		if (skill !== prevProps.skill || location !== prevProps.location) {
			SearchJobBySkill(this.onJobListFetched, skill || "$-$", location || "$-$");
		}
	};

	onJobListFetched = (data) => {
		if (data && data.length) {
			this.setState({
				list: data
			});
		}
	};
	getList = () => {
		const {classes, uploadResumeForJobClicked} = this.props;
		let {list} = this.state;
		return list.map((item, index) => {
			let {jobPosition, jobLocation = "-", jobPostDate = Date.now(), _id} = item;
			if(jobPostDate === "") {
				jobPostDate = Date.now();
			}
			return (
				<Grid item xs={12} sm={6} key={index}>
					<div className={classes.jobContainer}>
						<div>
							<div>
								<div className={classes.jobIcon}/>
							</div>
							<div>
								<div className={classes.jobTitle}>{jobPosition}</div>
								<div className={classes.jobLocation}>{jobLocation}</div>
								<div className={classes.jobCreatedOn}>
									<span className={classes.jobPostText}>Job Posted :</span>
									<span>{moment(jobPostDate).format("MMMM Do, YYYY")}</span>
								</div>
								<div className={classes.jobApply}>
									<Link to={"/job-view/" + _id}>Know More</Link>
								</div>
							</div>
						</div>
					</div>
				</Grid>
			);
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			list: []
		};
	}

	render() {
		return this.state.list.length ? (this.getList()) : (<Grid item xs={12}>
			<div>No jobs to display</div>
		</Grid>);
	}
}

export default connect(null, {GetPublicJobList, SearchJobBySkill})(withStyles(styles)(JobList));