import {TYPES} from "./documen-action";

export default (state = {termOfUse: '', privacyPolicy: '', aboutUs: ''}, action = {}) => {
	switch (action.type) {
		case TYPES.GET_TERM_OF_USE:
			return {...state, ...{termOfUse: action.payload.data}};
		case TYPES.GET_PRIVACY_POLICY:
			return {...state, ...{privacyPolicy: action.payload.data}};
		case TYPES.GET_ABOUT_US:
			return {...state, ...{aboutUs: action.payload.data}};
		default:
			return state;
	}
}