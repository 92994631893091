import React from "react";
import {isMobile} from "../../../utils/detectBrowser";
import {getCategoryName} from "../job-post/jobMenuOptions";
import MobileView from "./mobileView";
import RemoveEmail from "./removeEmail";

export const config = (removeFromMailingList) => {
	if (isMobile.any()) {
		return [{
			flexGrow: 1.0,
			width: 500,
			headerStyle: {
				height: 0
			},
			cellContentRenderer: (props) => {
				return (
					<MobileView {...props} removeFromMailingList={removeFromMailingList}/>
				);
			}
		}];
	}
	else {
		return [{
			width: 400,
			label: 'Name',
			dataKey: 'name'
		}, {
			width: 400,
			label: 'Email Id',
			flexGrow: 1.0,
			dataKey: 'email'
		}, {
			width: 200,
			label: 'Category',
			flexGrow: 1.0,
			cellContentRenderer: (props) => {
				return (
					<div>{getCategoryName(props.rowData.category)}</div>
				);
			}
		}, {
			width: 50,
			label: "",
			cellContentRenderer: (props) => {
				return (
					<RemoveEmail {...props} removeFromMailingList={removeFromMailingList}/>
				);
			}
		}];
	}
};