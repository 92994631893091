import React from "react";
import {Route, Switch} from "react-router-dom";
import {APP_URLS} from "./appUrl";
import MainLoggedIn from "./authenticated-pages/main"
import MainLoggedOut from "./non-auth-pages/non-auth-main";

export const AppRouter = () => {
	return (
		<div>
			<Switch>
				<Route exact path={APP_URLS.HOME.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.HOME.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.CAREER.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.CAREER.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.CAREER.url + "/:jobId"} render={(props) => {
					return (
						<MainLoggedOut jobId={props.match.params.jobId} pageKey={APP_URLS.CAREER.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.SERVICES.url} render={(props) => {
					return (
						<MainLoggedOut pageKey={APP_URLS.SERVICES.key} serviceName={"it-staffing"}/>
					);
				}}/>
				<Route exact path={APP_URLS.SERVICES.url + "/:serviceName"} render={(props) => {
					return (
						<MainLoggedOut pageKey={APP_URLS.SERVICES.key} serviceName={props.match.params.serviceName}/>
					);
				}}/>
				<Route exact path={APP_URLS.CONTACT_US.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.CONTACT_US.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.ABOUT_US.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.ABOUT_US.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.JOB_VIEW_PUBLIC.url + "/:jobId"} render={(props) => {
					return (
						<MainLoggedOut jobId={props.match.params.jobId} pageKey={APP_URLS.JOB_VIEW_PUBLIC.key}/>
					);
				}}/>
				<Route path={APP_URLS.SIGN_IN.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.SIGN_IN.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.TermsOfUse.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.TermsOfUse.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.PRIVACY_POLICY.url} render={() => {
					return (
						<MainLoggedOut pageKey={APP_URLS.PRIVACY_POLICY.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.DASHBOARD_HOME.url} render={() => {
					return (
						<MainLoggedIn pageKey={APP_URLS.DASHBOARD_HOME.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.NEW_JOB_POST.url} render={() => {
					return (
						<MainLoggedIn pageKey={APP_URLS.NEW_JOB_POST.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.VIEW_JOBS.url + "/:status"} render={(props) => {
					return (
						<MainLoggedIn urlJobStatus={props.match.params.status} pageKey={APP_URLS.VIEW_JOBS.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.EDIT_JOB_POST.url + "/:jobId"} render={(props) => {
					return (
						<MainLoggedIn jobId={props.match.params.jobId} pageKey={APP_URLS.EDIT_JOB_POST.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.CANDIDATE.url + "/:applicationStatus"} render={(props) => {
					return (
						<MainLoggedIn applicationStatus={props.match.params.applicationStatus}
						              pageKey={APP_URLS.CANDIDATE.key}/>
					);
				}}/>
				<Route exact path={APP_URLS.MAILING_LIST.url} render={(props) => {
					return (
						<MainLoggedIn pageKey={APP_URLS.MAILING_LIST.key}/>
					);
				}}/>
			</Switch>
		</div>
	);
};