import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, {Component} from 'react';
import {toast} from 'react-toastify';
import {validData} from "./dataValidation";
import * as MenuOptions from "./resumeUploadOptions";

const styles = theme => ({
	fileUploadButton: {
		textAlign: "center",
		border: "dashed 3px #fe985b",
		cursor: "pointer",
		padding: "16px 40px",
		textTransform: "uppercase",
		margin: "14px 0 0",
		overflow: "hidden",
		borderRadius: "4px",
		width: "100%",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	inputFile: {
		display: 'none',
	},
	gridContainer: {
		margin: 0
	},
	formControl: {
		margin: "16px 8px 0",
		minWidth: "100%"
	},
});
const DialogTitle = withStyles(theme => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
	},
}))(props => {
	const {children, classes, onClose} = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon/>
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class UploadResumeDialog extends Component {

	handleClose = () => {
		let {closeDialog} = this.props;
		if (closeDialog) {
			closeDialog();
		}
	};

	handleChanged = (e, stateName, directValue) => {
		let update = {};
		update[stateName] = directValue ? directValue.value : e.target.value;
		this.setState(update);
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let {
			firstName,
			lastName,
			emailId,
			phone,
			yearsOfExperience,
			currentLocation,
			expectedSalary,
			workAuthorization,
			c2cRate,
			supplierName,
			availability,
			resume,
			skills = []
		} = this.state;

		let formData = new FormData();
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("emailId", emailId);
		formData.append("phone", phone);
		formData.append("yearsOfExperience", yearsOfExperience);
		formData.append("currentLocation", currentLocation);
		formData.append("expectedSalary", expectedSalary);
		formData.append("workAuthorization", workAuthorization);
		formData.append("c2cRate", c2cRate);
		formData.append("availability", availability);
		formData.append("supplierName", supplierName);
		formData.append("resume", resume);
		formData.append("skills", skills.join(","));
		if (this.props.jobId) {
			formData.append("jobId", this.props.jobId);
		}
		this.props.onSubmit(formData);
	};

	validate = () => {
		return validData(this.state);
	};

	getMenu = (field) => {
		let menus = [];
		menus.push(<MenuItem value=""><em>None</em></MenuItem>);
		MenuOptions[field].map((item, index) => {
			menus.push(<MenuItem value={item.value} key={index}>{item.text}</MenuItem>);
		});
		return menus;
	};

	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			emailId: "",
			phone: "",
			yearsOfExperience: "",
			currentLocation: "",
			expectedSalary: "",
			workAuthorization: "",
			c2cRate: "",
			supplierName: "",
			availability: "",
			resume: null,
			skills: [],
			jobId: props.jobId
		};
	}

	render() {
		let {
			firstName,
			lastName,
			emailId,
			phone,
			yearsOfExperience,
			currentLocation,
			expectedSalary,
			workAuthorization,
			c2cRate,
			supplierName,
			availability,
			resume,
			skills
		} = this.state;
		let {classes, jobPosition, jobId} = this.props;
		return (
			<div>
				<Dialog
					open={true}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
						{jobId ? ("Apply for: " + jobPosition) : "Upload Resume"}
					</DialogTitle>
					<form onSubmit={this.handleSubmit} ref={(ref) => {
						this.resumeUploadForm = ref
					}}>
						<DialogContent>
							<Grid container xs={12} justify="center" spacing={40} className={classes.gridContainer}>
								<Grid item xs={12} sm={6}>
									<TextField
										id="first-name"
										label="First Name"
										required
										fullWidth
										autoFocus
										value={firstName}
										onChange={(e) => {
											this.handleChanged(e, "firstName");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="last-name"
										label="Last Name"
										required
										fullWidth
										value={lastName}
										onChange={(e) => {
											this.handleChanged(e, "lastName");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="email-id"
										label="Email Id"
										required
										fullWidth
										value={emailId}
										onChange={(e) => {
											this.handleChanged(e, "emailId");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="phone"
										label="Phone"
										required
										fullWidth
										value={phone}
										onChange={(e) => {
											this.handleChanged(e, "phone");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl className={classes.formControl} required>
										<InputLabel htmlFor="candidate-skill-list">Skills</InputLabel>
										<Select
											multiple
											value={skills}
											required
											onChange={(e) => {
												this.handleChanged(e, "skills")
											}}
											input={<Input id="candidate-skill-list"/>}
											renderValue={selected => {
												let text = "";
												for (let i in selected) {
													if (selected.hasOwnProperty(i)) {
														text += MenuOptions.getCandidateSkillText(selected[i]) + ", "
													}
												}
												text = text.substring(0, text.length - 2);
												return text;
											}}
											MenuProps={MenuProps}>
											{MenuOptions.candidateSkillTypes.map((item, index) => (
												<MenuItem key={index} value={item.value}>
													<Checkbox color={"primary"}
													          checked={skills.indexOf(item.value) > -1}/>
													<ListItemText primary={item.text}/>
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="year-of-exp"
										type={"number"}
										label="Years of experience"
										required
										fullWidth
										value={yearsOfExperience}
										onChange={(e) => {
											this.handleChanged(e, "yearsOfExperience");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="current-location"
										label="Current Location"
										fullWidth
										value={currentLocation}
										onChange={(e) => {
											this.handleChanged(e, "currentLocation");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="expected-salary"
										label="Expected Salary"
										required
										type={"number"}
										fullWidth
										value={expectedSalary}
										onChange={(e) => {
											this.handleChanged(e, "expectedSalary");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="c-two-c-rate"
										label="C2C Rate"
										value={c2cRate}
										fullWidth
										onChange={(e) => {
											this.handleChanged(e, "c2cRate");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="supplier-name"
										label="Supplier Name"
										value={supplierName}
										fullWidth
										onChange={(e) => {
											this.handleChanged(e, "supplierName");
										}}
										variant="outlined"
										margin="normal"/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl className={classes.formControl}>
										<InputLabel htmlFor="work-authorization">Work Authorization</InputLabel>
										<Select
											value={workAuthorization}
											onChange={(e) => {
												this.handleChanged(e, "workAuthorization")
											}}
											fullWidth
											inputProps={{
												name: 'workAuthorization',
												id: 'work-authorization',
											}}>
											{this.getMenu("workAuthorization")}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl className={classes.formControl}>
										<InputLabel htmlFor="work-authorization">Availability</InputLabel>
										<Select
											value={availability}
											onChange={(e) => {
												this.handleChanged(e, "availability")
											}}
											fullWidth
											inputProps={{
												name: 'candidateAvailability',
												id: 'candidate-availability',
											}}>
											{this.getMenu("candidateAvailability")}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={0} sm={6}/>
								<Grid item xs={12}>
									<FormControl className={classes.formControl}>
										<input
											accept="application/pdf"
											className={classes.inputFile}
											id="contained-button-file"
											onChange={(e) => {
												let file = e.target.files[0];
												if (file.size <= (3 * 1000000)) {
													this.handleChanged(e, "resume", {value: file});
												}
												else {
													toast.error(<div>{"File size should be less than 3MB."}</div>);
												}
											}}
											variant="outlined"
											type="file"/>
										<label htmlFor="contained-button-file">
											<div className={classes.fileUploadButton}>
												{resume ? resume.name : "Upload Resume (.pdf, max 3MB)"}
											</div>
										</label>
									</FormControl>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Cancel
							</Button>
							<Button disabled={this.validate()}
							        type={"submit"}
							        color="primary">
								Submit
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	}

}

export default withStyles(styles)(UploadResumeDialog);
