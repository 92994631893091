import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {APIs} from "../../api/index";

export const TYPES = {
	POST_CONTACT_US: "POST_CONTACT_US_2"
};

export const SaveContactUs = (data, cb) => {
	const request = axios.post(APIs.CONTACT_US_PUBLIC, data);
	request.then((response) => {
		toast.info(<div>{"Successfully submitted"}</div>);
		cb();
	}).catch((err) => {
		toast.info(<div>{"Unable to save information"}</div>);
	});
};