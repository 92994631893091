import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {GetJobDetailsPublic, PostPublicResume} from "../../../redux/commonStoreAndActions/job-actions";
import {getJobDuration} from "../../authenticated-pages/job-post/jobMenuOptions";
import ResumeUpload from "./uploadResume";

let moment = require('moment');
const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: "80%",
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	applyButtonContainer: {
		display: "block",
		textAlign: 'right',
		width: '100%',
		height: "40px",
	},
	jobPosition: {
		textAlign: 'right',
		fontSize: "24px",
		fontWeight: "bold",
		textDecoration: "underline",
		margin: "0 0 40px"
	},
	jobDetails: {
		textAlign: 'left',
		width: '100%'
	},
	row: {
		marginBottom: "20px"
	},
	field: {
		fontWeight: "bold",
		marginRight: "20px"
	},
	fieldValue1: {
		marginTop: "10px"
	}
});

class JobViewPublic extends Component {
	componentDidMount = () => {
		let {jobId, GetJobDetailsPublic} = this.props;
		if (jobId) {
			GetJobDetailsPublic(jobId);
		}
	};
	uploadResumeClicked = () => {
		this.setState({
			showResumeUpload: true
		});
	};
	closeResumeUploadDialog = () => {
		this.setState({
			showResumeUpload: false
		});
	};
	uploadResume = (data) => {
		PostPublicResume(data, () => {
			this.closeResumeUploadDialog();
			if (this.state.jobId) {
				toast.info(<div>{"Successfully applied for the job"}</div>);
			}
			else {
				toast.info(<div>{"Successfully uploaded the resume"}</div>);
			}
		});
	};
	uploadResumeForJobClicked = () => {
		this.setState({
			showResumeUpload: true
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			showResumeUpload: false
		}
	}

	render() {
		let {showResumeUpload, jobId, jobPosition} = this.state;
		let {classes, jobDetails} = this.props;
		let {
			jobPostDate = Date.now(),
			jobLocation = "-",
			jobDuration,
			requiredSkills,
			responsibility,
			preferredSkills,
			jobNotes = "-"
		} = jobDetails;
		if(jobPostDate === "") {
			jobPostDate = Date.now();
		}
		return (
			<main className={classes.main}>
				{showResumeUpload ? (
					<ResumeUpload closeDialog={this.closeResumeUploadDialog}
					              onSubmit={this.uploadResume}
					              jobId={jobDetails._id}
					              jobPosition={jobDetails.jobPosition}/>
				) : null}
				<Paper className={classes.paper}>
					<div className={classes.applyButtonContainer}>
						<Button onClick={this.uploadResumeForJobClicked} color="primary">
							Apply
						</Button>
					</div>
					<div className={classes.jobPosition}>
						{jobDetails.jobPosition}
					</div>
					<div className={classes.jobDetails}>
						<div className={classes.row}>
							<span className={classes.field}>Job Posted :</span>
							<span className={classes.fieldValue}>{moment(jobPostDate).format("MMMM Do, YYYY")}</span>
						</div>
						<div className={classes.row}>
							<span className={classes.field}>Job Location:</span>
							<span className={classes.fieldValue}>{jobLocation}</span>
						</div>
						<div className={classes.row}>
							<span className={classes.field}>Job Duration:</span>
							<span
								className={classes.fieldValue}>{getJobDuration(jobDuration) + (jobDuration !== 1000 && jobDuration !== "" ? " Months": "")}</span>
						</div>
						<div className={classes.row}>
							<div className={classes.field}>Required Skills:</div>
							<div className={classes.fieldValue1}>{requiredSkills}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.field}>Role Description:</div>
							<div className={classes.fieldValue1}>{responsibility}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.field}>Preferred Skills:</div>
							<div className={classes.fieldValue1}>{preferredSkills}</div>
						</div>
						{jobNotes !== "-" && (
							<div className={classes.row}>
								<div className={classes.field}>Notes:</div>
								<div className={classes.fieldValue1}>{jobNotes}</div>
							</div>
						)}
					</div>
				</Paper>
			</main>
		);
	}
}

JobViewPublic.propTypes = {
	jobId: PropTypes.string.isRequire,
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	jobDetails: state.job.publicJobDetails
});

export default connect(mapStateToProps, {GetJobDetailsPublic})(withStyles(styles)(JobViewPublic));