import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, {Component} from "react";
import validator from "validator";
import {SaveContactUs} from "../../../redux/commonStoreAndActions/contactus-actions";

const styles = theme => (
	{
		container: {
			display: 'flex',
			flexWrap: 'wrap',
			padding: "20px"
		},
		headMessage: {
			lineHeight: "1.48",
			letterSpacing: "0.7px",
			color: "#2c2c2c",
			marginBottom: "20px",
			fontSize: "32px"
		},
		message: {
			marginBottom: "20px",
			fontSize: "16px"
		},
		textField: {
			marginRight: theme.spacing.unit * 3,
			width: "100%",
		},
		agree: {
			lineHeight: "1.82",
			letterSpacing: "0.4px",
			color: "#626262",
			fontSize: "12px"
		},
		button: {
			borderRadius: "4px",
			backgroundImage: "linear-gradient(to right, #fe985b, #fe4fa7)",
			margin: "0 20px",
			color: "#fff",
			"&:disabled": {
				backgroundImage: "linear-gradient(to right, #a1a1a1, #a1a1a1)",
				color: "#c2c2c2",
			}
		},
		address: {
			color: "#626262",
			fontSize: "16px",
			margin: "72px 0 0",
			"&>div": {
				margin: "20px 0"
			},
			"&>div:first-child": {
				letterSpacing: "0.4px",
				textTransform: "uppercase",
			},
			"&>div:last-child": {
				lineHeight: "1.55",
				letterSpacing: "-0.1px",
			}
		},
		addressMessage: {
			color: "#2c2c2c",
			letterSpacing: "0.4px",
			margin: "60px 0 20px",
		},
		phone: {
			color: "#626262",
			margin: "20px 0",
			"&>a:hover": {
				textDecoration: "underline",
				color: "#276aff"
			}
		},
		mapContainer: {
			textAlign: "center"
		},
		map: {
			width: "640px",
			margin: "0 auto",
			[theme.breakpoints.up('xs')]: {
				width: "100%",
			},
			[theme.breakpoints.up('sm')]: {
				width: "640px",
			},
		}
	}
);

class ContactUs extends Component {
	componentDidMount = () => {
		let html = document.documentElement;
		html.scrollTop = 0;
	};
	handleChange = (e, field) => {
		let data = {};
		data[field] = e.target.value;
		this.setState(data);
	};
	validate = () => {
		let {
			firstName,
			emailId,
			message
		} = this.state;
		return firstName && firstName.length > 2 &&
			emailId && validator.isEmail(emailId) &&
			message && message.length > 10;
	};
	onFormSubmit = (e) => {
		e.preventDefault();
		let {
			firstName,
			lastName,
			emailId,
			message
		} = this.state;
		SaveContactUs({
			firstName: firstName,
			lastName: lastName,
			emailId: emailId,
			message: message
		}, () => {
			this.setState({
				firstName: "",
				lastName: "",
				emailId: "",
				message: ""
			});
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			emailId: "",
			message: ""
		};
	}

	render() {
		let {
				firstName,
				lastName,
				emailId,
				message
			} = this.state,
			{classes} = this.props;
		return (
			<div className={classes.container}>
				<Grid container spacing={40} justify="center">
					<Grid item xs={12} sm={6}>
						<div className={classes.headMessage}>
							How can we assist you?
						</div>
						<div className={classes.message}>
							Please send us a message by filling in the fields below. We will respond as soon as possible.
						</div>
						<Grid item xs={12}>
							<form onSubmit={this.onFormSubmit} className={classes.form}>
								<Grid container spacing={40} justify="flex-start">
									<Grid item xs={12} sm={6}>
										<TextField
											id="outlined-first-name-input"
											label="First Name"
											className={classes.textField}
											type="text"
											value={firstName}
											required
											onChange={(e) => {
												this.handleChange(e, "firstName");
											}}
											name="first-name"
											autoComplete="firstName"
											margin="normal"
											variant="outlined"/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											id="outlined-last-name-input"
											label="Last Name"
											className={classes.textField}
											type="text"
											value={lastName}
											onChange={(e) => {
												this.handleChange(e, "lastName");
											}}
											name="last-name"
											autoComplete="lastName"
											margin="normal"
											variant="outlined"/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-last-name-input"
											label="Email id"
											required
											className={classes.textField}
											type="text"
											value={emailId}
											onChange={(e) => {
												this.handleChange(e, "emailId");
											}}
											name="email"
											autoComplete="email"
											margin="normal"
											variant="outlined"/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-message-input"
											label="Message"
											required
											className={classes.textField}
											type="text"
											value={message}
											onChange={(e) => {
												this.handleChange(e, "message");
											}}
											name="message"
											margin="normal"
											variant="outlined"/>
									</Grid>
									<Grid item xs={12} className={classes.agree}>
										I hereby certify that all the information above is true and accurate.
									</Grid>
									<Grid>
										<Button type={"submit"} disabled={!this.validate()}
										        className={classes.button}
										        variant="contained">Submit</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.headMessage}>
							How to reach Us?
						</div>
						<div className={classes.message}>
							Our corporate office address is below. Use the map to locate our office.
						</div>
						<div className={classes.address}>
							<div>United States of America</div>
							<div>337 Deaven Rd, Harrisburg, PA 17111, USA</div>
						</div>
						<div className={classes.phone}>TEL <a href="tel:+1 717-616-4114" target="_top">+1
							717-616-4114</a></div>
						<div className={classes.phone}>FAX <a href="tel:+1 717-616-4111" target="_top">+1
							717-616-4111</a></div>
						<div className={classes.phone}>EMAIL <a href="mailto:info@collabriumsystems.com"
						                                        target="_top">info@collabriumsystems.com</a></div>
					</Grid>
					<Grid item xs={12} className={classes.mapContainer}>
						<img alt={"Collabrium Systems location on map"}
						     className={classes.map}
						     async
						     src={"https://maps.googleapis.com/maps/api/staticmap?center=40.3095163,-76.7650678&markers=Collabrium Systems&zoom=8&format=JPEG&scale=2&size=640x200&key=AIzaSyB-6-qxKgLdwSvOV9x3-QC6KLWOEFVMJ0c"}/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(ContactUs);

