import CssBaseline from '@material-ui/core/CssBaseline';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import {ToastContainer} from 'react-toastify';
import {AppRouter} from "./ui-components/app-router";
import Loader from "./ui-components/common/loader";

class App extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<Loader/>
				<CssBaseline/>
				<BrowserRouter>
					<AppRouter/>
				</BrowserRouter>
				<ToastContainer/>
			</Fragment>
		);
	}
}

export default connect()(App);
