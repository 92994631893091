import axios from "axios";
import {APIs} from "../../api/index";

export const TYPES = {
	ON_LOGIN: "ON_LOGIN_20",
	ON_LOGOUT: "ON_LOGOUT_21"
};

export const SignInUser = (email, password) => {
	const request = axios.post(APIs.SIGN_IN, {
		email: email, password: password
	});
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.ON_LOGIN,
				payload: data
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};

export const LogoutUser = () => {
	const request = axios.post(APIs.LOG_OUT);
	return (dispatch) => {
		request.then((data) => {
			dispatch({
				type: TYPES.ON_LOGOUT,
				payload: data
			});
		}).catch((err) => {
			console.log(err);
		});
	}
};