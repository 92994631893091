import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {connect} from "react-redux";
import {GetCandidateList, ChangeResumeStatus} from "../../../redux/commonStoreAndActions/candidate-action";
import Table from "../../../ui-common/table";
import {isMobile} from "../../../utils/detectBrowser";
import {getCandidateJobApplicationText} from "./candidateMenuOptions";
import {config} from "./tableConfig";

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	tableContainer: {
		height: "calc(100vh - 160px)"
	},
});

class CandidateView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ''
		};
	}
	getTableData = (data, callback) => {
		data.status = this.props.status;
		this.props.GetCandidateList(data, callback);
	};

	changeResumeStatus = (data) => {
		this.props.ChangeResumeStatus(data, () => {
			this.table.resetTable();
		});
	};

	render() {
		let {classes, job} = this.props;
		return (
			<div className={classes.tableContainer}>
				<Typography variant="h4" gutterBottom component="h4">
					{"Candidate Type - " + getCandidateJobApplicationText(this.props.status)}
				</Typography>
				<Table
					ref={(ref) => {
						this.table = ref;
					}}
					rowHeight={isMobile.any() ? 450 : 56}
					headerHeight={isMobile.any() ? 0 : 56}
					getTableData={this.getTableData}
					search={"skills"}
					config={config(this.props.status, this.changeResumeStatus)}/>
			</div>
		);
	}
}

CandidateView.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	job: state.job
});

export default connect(mapStateToProps, {
	GetCandidateList,
	ChangeResumeStatus
})(withStyles(styles)(CandidateView));