export const candidateJobApplicationType = [{
	text: "New Resumes", value: "new"
}, {
	text: "Applied Jobs", value: "new_apply"
}, {
	text: "Future Use", value: "future"
}, {
	text: "Rejected", value: "rejected"
}];

export const candidateJobApplicationTypeChangeTo = [{
	text: "Future Use", value: "future"
}, {
	text: "Rejected", value: "rejected"
}];

export const getCandidateJobApplicationText = (value) => {
	let returnValue = "-";
	for (let i in candidateJobApplicationType) {
		if (candidateJobApplicationType.hasOwnProperty(i) && candidateJobApplicationType[i].value === value) {
			returnValue = candidateJobApplicationType[i].text;
			break;
		}
	}
	return returnValue;
};